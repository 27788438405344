import { Injectable, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { sessionStorage, localStorage } from '@shared/services/storage_service';

import { LoggingService } from '../services/logging_service';

import { PurchaseTokenCacheEntry, PurchaseTokenCacheMap } from '../types/purcase_token_cache_entry';

let auth = ''; // token gets stored in memory

const purchase_tokens: PurchaseTokenCacheMap = new PurchaseTokenCacheMap(); // token gets stored in memory

export function setAuthToken(token: string, stayLoggedIn?: boolean) {
  auth = token;

  sessionStorage.setItem('authorization', token);
  if (!localStorage.getItem('successLogin')) {
    localStorage.setItem('successLogin', 'true');
  }

  sessionStorage.setItem('authorization', token);

  if (stayLoggedIn) {
    localStorage.setItem('authorization', token);
  }
}

export function setPurchaseToken(token: string, postId: string, playlistId: string) {
  console.log('setPurchaseToken', token, postId);

  if (!token || token.length < 10) {
    return;
  }

  if (!postId && !playlistId) {
    return;
  }

  const entry = new PurchaseTokenCacheEntry();
  if (postId) {
    entry.postId = postId;
  }

  if (playlistId) {
    entry.playlistId = playlistId;
  }

  entry.purchase_token = token;
  entry.timestamp = Date.now();
  purchase_tokens[postId] = entry;

  try {
    const serialized = JSON.stringify(purchase_tokens);

    // save serialized data in sessionStorage. Gets lost on browser close
    sessionStorage.setItem('purchase_tokens', serialized);

    localStorage.setItem('purchase_tokens', serialized);
  } catch (e) {
    console.warn('Localstorage not available. can not store purchase token');
  }
}

export function getCachedTokenByKey(key: string): string {
  let cached_tokens: PurchaseTokenCacheMap = null;

  try {
    if (sessionStorage.getItem('purchase_tokens')) {
      // Restore the contents of the text field
      cached_tokens = JSON.parse(sessionStorage.getItem('purchase_tokens'));
    } else if (localStorage.getItem('purchase_tokens')) {
      // Restore the contents of the text field
      cached_tokens = JSON.parse(localStorage.getItem('purchase_tokens'));
    }
    // return if we have it
    if (cached_tokens && cached_tokens[key]) {
      return cached_tokens[key].purchase_token;
    }
  } catch (e) {
    return null;
  }

  return null;
}

export function getPurchaseTokenForUrl(url: string): string {
  if (url.indexOf('/api/post/') === -1 && url.indexOf('/api/playlist/') === -1) {
    return null;
  }

  if (url.indexOf('/api/post/') !== -1) {
    const parts = url.split('/api/post/');
    if (parts.length < 2) {
      return null;
    }

    const postId = parts[1].split('/')[0];
    if (purchase_tokens && purchase_tokens[postId]) {
      return purchase_tokens[postId].purchase_token;
    }

    return getCachedTokenByKey(postId);
  }

  if (url.indexOf('/api/playlist/') !== -1) {
    const parts = url.split('/api/playlist/');
    if (parts.length < 2) {
      return null;
    }
    const id = parts[1].split('/')[0];

    if (purchase_tokens && purchase_tokens[id]) {
      return purchase_tokens[id].purchase_token;
    }

    return getCachedTokenByKey(id);
  }

  return null;
}

// Get auth token, called here and media component for HLS
export function getAuthToken(): string {
  if (!auth) {
    try {
      if (sessionStorage.getItem('authorization')) {
        // Restore the contents of the text field
        auth = sessionStorage.getItem('authorization');
      } else if (localStorage.getItem('authorization')) {
        // Restore the contents of the text field
        auth = localStorage.getItem('authorization');
      }
    } catch (e) {
      return '';
    }
  }

  return auth;
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  BASE_API: string;

  private base_api_url = '';

  constructor(
    @Inject('ORIGIN_URL') protected originUrl: string,
    private router: Router,
    private logger: LoggingService
  ) {
    // this.BASE_API = environment.apiServer;
    if (!this.BASE_API) {
      this.BASE_API = originUrl;
      this.base_api_url = originUrl + '/api';
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.startsWith('/api/') && !req.url.startsWith(this.base_api_url)) {
      return next.handle(req);
    }

    const authToken = getAuthToken();

    const paymentToken = getPurchaseTokenForUrl(req.url);

    let serverReq = req.clone({
      url: this.apiUrl(req.url),
      withCredentials: false,
    });

    if (authToken && authToken.length > 1) {
      // authToken is set, so add it to headers
      serverReq = serverReq.clone({
        setHeaders: { 'Postd-Auth': authToken },
      });
    }

    if (paymentToken) {
      serverReq = serverReq.clone({
        setHeaders: { 'Transact-Payment': paymentToken },
      });
    }

    return next.handle(serverReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          //     console.log('HttpEvent', event);
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse && err.url) {
            if (err.status === 403) {
              if (err.url.endsWith('/user/profile')) {
                // not logged in
              }
            }
            if (err.status < 100 || err.status >= 500) {
              if (err.url.endsWith('/user/profile')) {
                // probably can't access api
                console.log('critical user/profile error ');
                this.router.navigate(['error', 'api']);
              }

              this.logger.sendError(
                err.status + ' Error: ' + err.message,
                this.router.url,
                -1,
                '',
                {
                  code: err.error ? err.error.code : -1,
                  request: JSON.stringify(serverReq),
                }
              );
            }
          }
        }
      )
    );
  }
  private clearAuthToken() {
    auth = '';
  }

  private apiUrl(url: string): string {
    // if we should prefix our API server
    if (url.startsWith('/api')) {
      return this.BASE_API + url;
    } else if (this.originUrl && url.startsWith('/')) {
      return this.originUrl + url;
    }

    return url;
  }
  private checkAuthToken() {
    if (!auth) {
      try {
        if (sessionStorage.getItem('authorization')) {
          // Restore the contents of the text field
          auth = sessionStorage.getItem('authorization');
        } else if (localStorage.getItem('authorization')) {
          // Restore the contents of the text field
          auth = localStorage.getItem('authorization');
        }
      } catch (e) {
        return '';
      }
    }

    return auth;
  }
}
