import { ActivatedRoute } from '@angular/router';
declare let transactApi: any;

import * as Debug from 'debug';
const debug = Debug('shared:PurchaseService');

import { Injectable, Inject, Renderer2, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable, Subject, from, of, timer, throwError, EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, retryWhen, share, take } from 'rxjs/operators';

import { Payment } from '../types/payment';
import { environment } from '@env/environment';
import { AnalyticsCustomEvents, AnalyticsService } from './analytics_service';

interface PurchaseConfig<T> {
  catchError?: (event: any) => Observable<string>;
  map?: (token: string) => T;
  flatMap?: (token: string) => Observable<T>;
}

export class PurchaseMicroService<T = string> {
  mapFunc: (token: string) => T;
  flatMapFunc: (token: string) => Observable<T>;
  catchFunction: (err: any) => Observable<string>;
  transactApi: any;

  transactModal?: HTMLElement;
  transactBackdrop?: HTMLElement;

  private resizeListener: EventListener;

  constructor(
    private prePurchaseToken: string,
    purchaseConfig: PurchaseConfig<T>,
    private apiLoadedState: Observable<any>,
    private _document: Document,
    private analytics: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.apiLoadedState.subscribe(
      (loadedTransactApi) => {
        if (loadedTransactApi) {
          this.transactApi = loadedTransactApi;
          loadedTransactApi.setToken(prePurchaseToken);
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        console.log('done');
      }
    );

    this.mapFunc = purchaseConfig.map;
    this.flatMapFunc = purchaseConfig.flatMap;
    this.catchFunction = purchaseConfig.catchError;
  }

  purchase(tryCount = 0): Observable<T> {
    const purchaseState = new Subject<string>();

    if (this.transactModal) {
      // if transact purchase modal is already open, don't do anything.
      return EMPTY;
    }

    if (!this.prePurchaseToken) {
      throw Error('Transact API Token not loaded');
    }
    if (!this.transactApi) {
      // If transact API hasn't loaded yet and the user is trying to purchase, then fail
      if (tryCount > 10) {
        return throwError({
          validation: null,
          popupClosed: true,
          event: { msg: 'Transact API could not be loaded. Please try purchasing again.' },
          complete: false,
        });
      } else {
        return timer(500).pipe(mergeMap(() => this.purchase(tryCount++)));
      }
    } else {
      this.transactApi.setToken(this.prePurchaseToken);
      // open the purchase modal here

      // Call authorize() which will load the popup,
      // passing in callback function (PurchasePopUpClosed)
      const frame = this.openPurchaseIFrame(purchaseState);
      console.log('calling transactApi.authorize');
      if (typeof frame === 'undefined') {
        this.transactApi.authorize((popup, event) => {
          this.transactCallback(purchaseState, popup, event);
        });
      } else {
        const tags = this.analytics.getSessionAnalyticsTags();
        const params = Object.keys(tags).map((key) => ({
          name: key,
          value: tags[key],
        }));

        this.transactApi.setAddtlParams(params);
        this.transactApi.authorizeInIframe(
          frame,
          (popup, event) => {
            this.transactCallback(purchaseState, popup, event);
          },
          (height) => {
            frame.style.height = height + 'px';
          }
        );
      }
    }

    const returnObservable = purchaseState.pipe(filter((token) => typeof token === 'string'));
    if (this.flatMapFunc) {
      return returnObservable.pipe(mergeMap(this.flatMapFunc));
    } else if (this.mapFunc) {
      return returnObservable.pipe(map(this.mapFunc));
    } else {
      return null;
    }
  }

  private openPurchaseIFrame(purchaseState: Subject<string>) {
    if (this.transactModal) {
      const existingFrame = this.transactModal.querySelector('iframe')[0];
      if (existingFrame) {
        return existingFrame;
      }
      return this.transactModal.querySelector('iframe')[0];
    }

    this.transactModal = this._document.createElement('div');
    this.transactModal.classList.add('transact-purchase-modal');
    this.transactBackdrop = this._document.createElement('div');
    this.transactBackdrop.classList.add('transact-purchase-backdrop');

    const close_button = this._document.createElement('div');
    close_button.classList.add('transact-purchase-modal__close-button');

    this.transactBackdrop.onclick = () =>
      this.transactCallback(purchaseState, { closed: true }, null);
    close_button.onclick = () => this.transactCallback(purchaseState, { closed: true }, null);

    const frame = this._document.createElement('iframe');
    frame.setAttribute('scrolling', 'no');
    frame.setAttribute('allow', 'payment;');
    frame.setAttribute('allowpaymentrequest', 'true');

    this._document.body.classList.add('no-scroll-mobile');
    this.transactModal.appendChild(close_button);
    this.transactModal.appendChild(frame);

    this._document.querySelector('body').appendChild(this.transactModal);
    this._document.querySelector('body').appendChild(this.transactBackdrop);

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (this.transactModal) {
          this.transactModal.classList.add('show'); // Trigger CSS transition
          const scrollY = window.scrollY || this._document.documentElement.scrollTop;

          // Make sure the modal is visible
          const modalPosition = this.transactModal.getBoundingClientRect() as DOMRect;
          if (modalPosition.y < 10) {
            this.transactModal.style.top = `${Math.round(scrollY) + 10}px`;
          }
        }
      }, 50);

      window.visualViewport.addEventListener('resize', resizeHandler);
      const visualViewport = window.visualViewport;
      let viewportHeight = visualViewport.height;
      visualViewport.addEventListener('resize', resizeHandler);
      this.resizeListener = resizeHandler;

      function resizeHandler() {
        if (this.transactModal) {
          if (!/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
            viewportHeight = visualViewport.height;
          }
          this.transactModal.style.bottom = `${viewportHeight - visualViewport.height + 10}px`;
        }
      }
    }

    return frame;
  }

  private transactCallback(purchaseState: Subject<string>, popup, event) {
    this._document.body.classList.remove('no-scroll-mobile');
    if (this.resizeListener) {
      window.visualViewport.removeEventListener('resize', this.resizeListener);
    }
    if (this.transactModal) {
      this._document.querySelector('body').removeChild(this.transactModal);
      this._document.querySelector('body').removeChild(this.transactBackdrop);

      delete this.transactModal;
      delete this.transactBackdrop;
    }

    console.log('transactApi.authorize event', event);
    console.log('transactApi.authorize popup', popup);
    const state = { completed: false };
    if (state.completed) {
      return;
    }

    if (event && event.data) {
      const validation_data = event.data;
      debug('purchase event.data', event.data);

      validation_data.action = 'getPurchasedContent';

      console.log('validation_data: ', validation_data);

      // var url = '/api/post/'+ post_id +'/purchase/validate';
      if (validation_data.t) {
        state.completed = true;
        this.analytics.fireEvent(AnalyticsCustomEvents.PurchaseComplete);
        purchaseState.next(validation_data.t);
        purchaseState.complete();
      } else {
        console.error('Purchase Failed', validation_data);
        purchaseState.error({
          validation: validation_data,
          popupClosed: popup.closed,
          event,
          complete: state.completed,
        });
      }
    } else {
      if (this.catchFunction !== undefined) {
        this.catchFunction(event).subscribe(
          (result) => {
            purchaseState.next(result);
            purchaseState.complete();
          },
          (err) => {
            console.log('Purchase Cancelled');
            purchaseState.error({
              popupClosed: popup.closed,
              complete: state.completed,
            });
          }
        );
      } else {
        if (!event && popup.closed) {
          console.log('Purchase Cancelled');
          purchaseState.error({
            popupClosed: popup.closed,
            complete: state.completed,
          });
        } else {
          console.error('Purchase Failed ');
          purchaseState.error({
            popupClosed: popup.closed,
            event,
            complete: state.completed,
          });
        }
      }
    }

    return purchaseState;
  }
}

@Injectable()
export class PurchaseService {
  transactApiLoadedState: Observable<any>;
  renderer: Renderer2;
  maxRetryAttempts = 3;
  queryParams: Array<{
    name: string;
    value: string;
  }>;

  constructor(
    rendererFactory: RendererFactory2,
    private http: HttpClient,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    @Inject(DOCUMENT) private _document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    debug('PurchaseService constructor');
    this.renderer = rendererFactory.createRenderer(null, null);
    this.route.queryParams.subscribe((queryParams) => {
      this.queryParams = [];
      for (const param in queryParams) {
        if (param.startsWith('utm_')) {
          this.queryParams.push({
            name: param,
            value: queryParams[param],
          });
        }
      }
      if (this.queryParams.length > 0 && typeof transactApi !== 'undefined') {
        transactApi.setAddtlParams(this.queryParams);
      }
    });
  }

  getPurchases(afterParam: any) {
    let params: HttpParams = new HttpParams();

    params = params.set('after', afterParam);

    return this.http
      .get<Payment>('/api/user/purchases', { params })
      .pipe(
        catchError((err) => {
          console.error('could not get latest purchases');
          return from([[]]);
        })
      )
      .toPromise();
  }

  preparePurchase<T = string>(
    prePurchaseToken: string,
    purchaseConfig: PurchaseConfig<T>
  ): Observable<PurchaseMicroService<T>> {
    if (typeof transactApi === 'undefined') {
      if (this.transactApiLoadedState === undefined) {
        let scriptElement: any;
        const transactApiLoader = new Observable((observer) => {
          if (scriptElement) {
            this.renderer.removeChild(this._document.body, scriptElement);
          }
          scriptElement = this.renderer.createElement('script');
          scriptElement.type = 'text/javascript';
          scriptElement.src = `${environment.transactBaseUrl}/assets/js/transact.js`;
          scriptElement.onload = () => {
            transactApi.setFrontendUrl(`${environment.transactBaseUrl}/purchase`);
            transactApi.setAddtlParams(this.queryParams);
            observer.next(transactApi);
            observer.complete();
          };

          scriptElement.onerror = (error: any) => {
            console.error("Couldn't load script " + scriptElement.src);
            observer.error(false);
          };
          this.renderer.appendChild(this._document.body, scriptElement);
        });
        this.transactApiLoadedState = transactApiLoader.pipe(
          retryWhen((errors) =>
            errors.pipe(
              mergeMap((val, i) => {
                if (i >= this.maxRetryAttempts) {
                  return throwError('Too many retries trying to load Transact API');
                }
                // retry in 2 seconds
                return timer(5000 * (i + 1));
              })
            )
          ),
          map(() => transactApi),
          share()
        );
      }
      return this.transactApiLoadedState.pipe(
        map(
          () =>
            new PurchaseMicroService<T>(
              prePurchaseToken,
              purchaseConfig,
              transactApi ? of(transactApi) : this.transactApiLoadedState,
              this._document,
              this.analytics,
              this.platformId
            )
        ),
        take(1)
      );
    } else {
      return of(
        new PurchaseMicroService<T>(
          prePurchaseToken,
          purchaseConfig,
          of(transactApi),
          this._document,
          this.analytics,
          this.platformId
        )
      );
    }
  }
}
