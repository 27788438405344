import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';

// services that are needed globally
import { AnalyticsService } from '@shared/services/analytics_service';
import { MetaService } from '@shared/services/meta_service';

@Component({
  selector: 'postd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./frontend/core/scss/main.scss', './app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  // Don't remove meta service as this is the only place where it gets initialzed
  constructor(analyticsService: AnalyticsService, router: Router, private meta: MetaService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // (window as any).ga('set', 'page', event.urlAfterRedirects);
        // (window as any).ga('send', 'pageview');
        analyticsService.pageView();
      }
    });
  }
}
