import { Routes, ActivatedRouteSnapshot, Router } from '@angular/router';

import { LoginGuard } from '@shared/guards/login.guard';
import { ManagePostGuard } from '@shared/guards/manage-post.guard';
import { ManageChannelGuard } from '@shared/guards/manage-channel.guard';
import { ManageTransfersGuard } from '@shared/guards/manage-earnings.guard';
import { CreateType } from '@shared/types/post';
import { PostService } from '@shared/services/post_service';
import { inject } from '@angular/core';

export const pathRootV2 = 'manage-v2/';
const pathRootV1 = 'manage/';

export const browserRoutes: Routes = [
  // New Manager
  {
    path: `${pathRootV2}post/${CreateType.Livestream}`,
    loadChildren: () =>
      import('./frontend/manage-post-v2/manage-post.module').then((m) => m.ManagePostV2Module),
    canActivate: [LoginGuard, ManageChannelGuard],
    data: { createType: CreateType.Livestream },
  },
  {
    path: `${pathRootV2}post/${CreateType.Video}`,
    loadChildren: () =>
      import('./frontend/manage-post-v2/manage-post.module').then((m) => m.ManagePostV2Module),
    canActivate: [LoginGuard, ManageChannelGuard],
    data: { createType: CreateType.Video },
  },
  {
    path: `${pathRootV2}post/${CreateType.Audio}`,
    loadChildren: () =>
      import('./frontend/manage-post-v2/manage-post.module').then((m) => m.ManagePostV2Module),
    canActivate: [LoginGuard, ManageChannelGuard],
    data: { createType: CreateType.Audio },
  },
  {
    path: `${pathRootV2}post/${CreateType.Article}`,
    loadChildren: () =>
      import('./frontend/manage-post-v2/manage-post.module').then((m) => m.ManagePostV2Module),
    canActivate: [LoginGuard, ManageChannelGuard],
    data: { createType: CreateType.Article },
  },
  {
    path: `${pathRootV2}post/:postId`,
    loadChildren: () =>
      import('./frontend/manage-post-v2/manage-post.module').then((m) => m.ManagePostV2Module),
    canActivate: [
      LoginGuard,
      ManageChannelGuard,
      (route: ActivatedRouteSnapshot) => {
        const postService = inject(PostService);
        const router = inject(Router);
        return postService.getPost(route.params.postId).then((post) => {
          const path = route.url.map((segment) => segment.path);
          path.splice(2, 0, post.premiumMediaType);
          console.log(route, post);
          router.navigate(path);
          return false;
        });
      },
    ],
  },

  // Old Manager
  {
    path: `${pathRootV1}post/channel/:chanId`,
    loadChildren: () =>
      import('./frontend/manage-post/manage-post.module').then((m) => m.ManagePostModule),
    canActivate: [LoginGuard, ManageChannelGuard],
  },
  {
    path: `${pathRootV1}post/video`,
    loadChildren: () =>
      import('./frontend/manage-post/manage-post.module').then((m) => m.ManagePostModule),
    canActivate: [LoginGuard, ManagePostGuard],
  },
  {
    path: `${pathRootV1}post/audio`,
    loadChildren: () =>
      import('./frontend/manage-post/manage-post.module').then((m) => m.ManagePostModule),
    canActivate: [LoginGuard, ManagePostGuard],
  },
  {
    path: `${pathRootV1}post/article`,
    loadChildren: () =>
      import('./frontend/manage-post/manage-post.module').then((m) => m.ManagePostModule),
    canActivate: [LoginGuard, ManagePostGuard],
  },
  {
    path: `${pathRootV1}post/:postId`,
    loadChildren: () =>
      import('./frontend/manage-post/manage-post.module').then((m) => m.ManagePostModule),
    canActivate: [LoginGuard, ManagePostGuard],
  },
  {
    path: `${pathRootV1}post`,
    loadChildren: () =>
      import('./frontend/manage-post/manage-post.module').then((m) => m.ManagePostModule),
    canActivate: [LoginGuard],
  },
  {
    path: `${pathRootV1}purchase-history`,
    loadChildren: () =>
      import('./frontend/manage-purchase-history/manage-purchase-history.module').then(
        (m) => m.ManagePurchaseHistoryModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: `${pathRootV1}earnings`,
    loadChildren: () =>
      import('./frontend/manage-earnings/manage-earnings.module').then(
        (m) => m.ManageEarningsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: `${pathRootV1}transfer`,
    loadChildren: () =>
      import('./frontend/manage-transfer/manage-transfer.module').then(
        (m) => m.ManageTransferModule
      ),
    canActivate: [ManageTransfersGuard],
  },
  {
    path: `${pathRootV1}channel-posts/:chanId`,
    loadChildren: () =>
      import('./frontend/manage-channel-posts/manage-channel-posts.module').then(
        (m) => m.ManageChannelPostsModule
      ),
    canActivate: [LoginGuard, ManageChannelGuard],
  },
  {
    path: `${pathRootV1}channel-posts`,
    loadChildren: () =>
      import('./frontend/manage-channel-posts/manage-channel-posts.module').then(
        (m) => m.ManageChannelPostsModule
      ),
    canActivate: [LoginGuard],
  },
];
