import * as Debug from 'debug';
const debug = Debug('shared:SelectedCategoryService');

import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { forEach } from 'lodash';

export interface TypeObject {
  label: string;
  value: string;
}

@Injectable()
export class SelectedCategoryService {

    currentType: TypeObject;
    currentClassification: string;

    listTypes: TypeObject[] = [
      { label: 'Watch', value: 'video' },
      { label: 'Listen', value: 'audio' },
      { label: 'Read', value: 'text' },
      { label: 'All', value: '' }
    ];

    typeSubject: BehaviorSubject<TypeObject>;
    classificationSubject: BehaviorSubject<string>;

    constructor() {
        this.currentType = this.listTypes[3]; // All
        this.currentClassification = '';

        this.typeSubject = new BehaviorSubject<TypeObject>(this.currentType);
        this.classificationSubject = new BehaviorSubject<string>(this.currentClassification);
    }

    getType(): TypeObject {
        return this.currentType;
    }

    getTypeChange() {
      return this.typeSubject.asObservable();
    }

    getList() {
      return this.listTypes;
    }

    setType(typeValue: string) {
      this.currentType = this.listTypes.find((type) => type.value === typeValue);
      this.typeSubject.next(this.currentType);
    }

    getClassification(): string {
        return this.currentClassification;
    }

    setClassification(className: string) {
        this.classificationSubject.next(className);
        this.currentClassification = className;
    }

    getClassificationTypeChange() {
      return this.classificationSubject.asObservable();
    }

    prepareTagsForUpdate(tags: string[], mediaType: string): string {
      forEach(tags, (t, i, l) => {
          const clasCat = t.split('-');
          if (clasCat.length > 1) {
              const newClas = clasCat[0];
              const newCat = t.slice(newClas.length + 1);
              l[i] = 'type-' + mediaType +
                  ' classification-' + newClas + ' category-' + newCat;
          }
      });
      return tags.join(' ');;
  }

}
