import {
  Component,
  Inject,
  Input,
  Output,
  EventEmitter,
  OnInit,
  PLATFORM_ID,
  ElementRef,
  HostListener,
  NgZone,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Post } from '@shared/types/post';
import { Router, ActivatedRoute } from '@angular/router';

import { CategoriesService } from '@shared/services/categories_service';
import { PostService } from '@shared/services/post_service';

import * as _ from 'lodash';

export interface DisplayOptions {
  hideDescription?: boolean;
  hidePrice?: boolean;
  showCategories?: boolean;
  showChannel?: boolean;
  showViewsDate?: boolean;
  showRating?: boolean;
  overlayInfo?: boolean;
  disablePreview?: boolean;
  hidePublishDate?: boolean;
  disableLink?: boolean;
}

@Component({
  selector: 'postd-post-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
})
export class ThumbnailComponent implements OnInit {
  _post: Post;
  thumbnailUrl: string;
  durationDisplay: string;
  selectedDisplayOptions: DisplayOptions;
  removable: boolean;

  mediaItem: any;
  mediaType: string;
  videoUrl: string = null;
  videoEnabled = false;
  player: any = '';
  previewEnable = false;
  link: string[] = [];

  @Input() set post(value: Post) {
    // copy object so we get methods
    this._post = new Post(value);
    if (!this.selectedDisplayOptions.disableLink) {
      this.link = ['/post', value.id, value.urlName];
    }
  }
  @Input() set displayOptions(options: any) {
    this.selectedDisplayOptions = _.defaults(options as DisplayOptions, this.defaultDisplayOptions);
    if (this.selectedDisplayOptions.disableLink) {
      this.link = [];
    }
  }

  @Output() removeAction = new EventEmitter();

  defaultDisplayOptions: DisplayOptions = {
    hideDescription: false,
    hidePrice: false,
    showCategories: false,
    showChannel: true,
    showViewsDate: true,
    showRating: false,
    overlayInfo: true,
    disablePreview: false,
    hidePublishDate: false,
    disableLink: false,
  };

  private onScroll(): void {
    if (!this._post.channelInfo || !this._post.channelInfo.id || this.thumbnailUrl === null) {
      const windowHeight = window.innerHeight;
      const thisPositionTop = this.elRef.nativeElement.getBoundingClientRect().top;
      if (thisPositionTop > 0 && thisPositionTop < windowHeight + 250) {
        this.zone.run(() => {
          this.setThumbnail();
          if (!this._post.channelInfo || !this._post.channelInfo.id) {
            this.loadChannel();
          }
        });
      }
    }
  }

  constructor(
    private catService: CategoriesService,
    private elRef: ElementRef,
    private router: Router,
    private postService: PostService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.thumbnailUrl = null;
    this.selectedDisplayOptions = this.defaultDisplayOptions;
  }

  ngOnInit() {
    if (this.removeAction.observers.length > 0) {
      this.removable = true;
    }

    this.previewEnable =
      !this.selectedDisplayOptions.disablePreview &&
      !_.isEmpty(this._post.leadMedia) &&
      this._post.leadMedia[this._post.leadMedia.length - 1]['statusCode'] === 'OK'
        ? true
        : false;

    if (isPlatformBrowser(this.platformId)) {
      this.onScroll();
      this.zone.runOutsideAngular(() => {
        window.addEventListener(
          'scroll',
          (e) => {
            this.onScroll();
          },
          { passive: true }
        );
        window.addEventListener(
          'resize',
          (e) => {
            this.onScroll();
          },
          { passive: true }
        );
      });
    }
  }

  setThumbnail() {
    this.thumbnailUrl = this.postService.getPostThumbnail(this._post, { 0: 300, 1400: 600 });
    if (this.thumbnailUrl === null) {
      this.thumbnailUrl = '';
    }
  }

  loadChannel() {
    this.postService.loadChannelForPost(this._post).subscribe();
  }

  removePost() {
    this.removeAction.emit(this._post);
  }

  normalizePostName(name: string) {
    if (!_.isEmpty(name)) {
      return this.postService.urlifyName(name);
    }
  }

  getCategories(tags: string[]): string[] {
    const categoryTags: string[] = [];
    _.forEach(tags, (t) => {
      const parts = t.split('-');
      if (parts.length === 2) {
        if (parts[0] === 'category') {
          categoryTags.push(this.catService.categoryToUrl(parts[1]));
        }
      }
    });

    return categoryTags;
  }

  startPreviewVideo() {
    this.initVideo();
  }

  onMouseEnter() {
    if (this.videoEnabled && !_.isEmpty(this.player)) {
      this.player.play();
    }
  }

  onMouseLeave() {
    if (!_.isEmpty(this.player) && this.videoEnabled) {
      this.player.pause();
    }
  }

  initVideo() {
    this.videoEnabled = true;

    const el = this.elRef.nativeElement.querySelector(`#video_${this._post.id}`);
    const videoJsConfig = {
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        vhs: {
          // debug: true,
          overrideNative: true,
        },
      },
      autoplay: true,
      preload: 'auto',
      // loop: true, don't loop cause the page starts to lag once multiple videos are playing
      controls: false,
      aspectRatio: this._post.premiumMediaType === 'audio' ? '1:1' : '16:9',
    };

    this.mediaItem = this.postService.getMediaItem(this._post, true);

    this.videoUrl = null;
    if (this.mediaItem && this.mediaItem.transcoded && this.mediaItem.transcoded.playlistUrl) {
      this.videoUrl = this.mediaItem.transcoded.playlistUrl;
    }
    if (this.mediaItem && this.mediaItem.liveUrl) {
      this.videoUrl = this.mediaItem.liveUrl;
    }

    if (this.videoUrl) {
      this.player = videojs(el, videoJsConfig);

      videojs.Vhs.xhr.beforeRequest = (options) => {
        if (options.uri.startsWith('/api')) {
          options.uri = document.location.origin + this.videoUrl;
        }
        return options;
      };

      this.player.poster(this.postService.getPostThumbnail(this._post));
      this.player.src({
        src: this.videoUrl,
        type: 'application/x-mpegURL',
        withCredentials: true,
      });
    }
  }

  redirectToPost() {
    this.router.navigate(['/post', this._post.id, this.normalizePostName(this._post.indexedName)]);
  }

  onMediaLoadError(event: Event) {
    console.log(event);
  }
}
