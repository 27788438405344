import { Pipe, PipeTransform } from '@angular/core';
import { mapping } from './cat-cat-mapping';

// const mapping = {
//     'animalspets'        : 'animals/pets',
//     'businessfinance'    : 'business/finance',
//     'caughtlive'         : 'caught live',
//     'computerscience'    : 'computer science',
//     'cookingfood'        : 'cooking/food',
//     'governmentpolitics' : 'government/politics',
//     'hiphop'             : 'hip hop',
//     'homeeconomics'      : 'home economics',
//     'howto'              : 'how to',
//     'languagearts'       : 'language arts',
//     'newslocal'          : 'news - local',
//     'newsstate'          : 'news - state',
//     'newsus'             : 'news - us',
//     'newsUS'             : 'news - us',
//     'newsworld'          : 'news - world' ,
//     'nonfiction'         : 'non-fiction',
//     'opinionscommentary' : 'opinions/commentary',
//     'politicsgovernment' : 'politics/government',
//     'sciencefiction'     : 'science fiction',
//     'socialstudies'      : 'social studies',
//     'ideassuggestions'   : 'ideas/suggestions',
//     'talkshows'          : 'talk shows',
//     'videoartists'       : 'video artists',
//     'worldgeography'     : 'world geography'
// };

@Pipe({
    name: 'clascatspc'
})
export class ClassificationCategorySpecialPipe implements PipeTransform {

  constructor() {}
  transform(item: string) {
    const result: string[] = [];
    const multiple = item.split(' ');
    multiple.forEach( s => {
        result.push(mapping[s] ? mapping[s] : s);
    });
    return result.join(' ');
  }
}
