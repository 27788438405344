import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {
  transform(html) {
      return html;
  }
}
