// Pull the pipes from Common to
// reuse in the backoffice app without dragging in all of the common elements

import { NgModule } from '@angular/core';
import { PostdCommonModule } from './common.module';

import { ClassificationCategoryCapitalizePipe } from '@shared/pipes/clas-cat-cap';
import { ClassificationCategorySpecialPipe } from '@shared/pipes/clas-cat-spc';
import { DurationFormatPipe } from '@shared/pipes/duration';
import { RelativeTimePipe } from '@shared/pipes/relative-time';
import { FormattedTimePipe } from '@shared/pipes/formatted-time';
import { EllipsisPipe } from '@shared/pipes/ellipsis';
import { SanitizePipe } from '@shared/pipes/sanitize';
import { UrlifyNamePipe } from '@shared/pipes/urlify';

@NgModule({
    declarations: [
        ClassificationCategoryCapitalizePipe,
        ClassificationCategorySpecialPipe,
        DurationFormatPipe,
        RelativeTimePipe,
        FormattedTimePipe,
        EllipsisPipe,
        SanitizePipe,
        UrlifyNamePipe
    ],
    imports: [
        PostdCommonModule
    ],
    exports: [
        ClassificationCategoryCapitalizePipe,
        ClassificationCategorySpecialPipe,
        DurationFormatPipe,
        RelativeTimePipe,
        FormattedTimePipe,
        EllipsisPipe,
        SanitizePipe,
        UrlifyNamePipe
    ]
})
export class SharedPipesModule {
}
