import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formattedTime'
})
export class FormattedTimePipe implements PipeTransform {
  transform(date: Date | number) {
    return (new Date(date)).toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' });
  }
}
