import * as Debug from 'debug';
const debug = Debug('perplay:MediaService');

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FileItem, FileUploader } from 'ng2-file-upload';

import { PostService } from './post_service';
import { Post } from '../types/post';

import { getAuthToken } from '@shared/interceptors/api.interceptor';
import { Observable } from 'rxjs';

export enum THUMBNAIL_ERROR {
  WRONG_FILETYPE,
  UPLOAD_FAILED,
  INVALID_IMAGE,
  UNKNOWN,
}

const validMimeTypes: { [key: string]: string[] } = {
  video: [
    'video/avi',
    'video/mp4',
    'application/x-mpegURL',
    'video/MP2T',
    'video/mp2t',
    'video/3gpp',
    'video/3gpp2',
    'video/ogg',
    'video/mpeg',
    'video/ms-asf',
    'video/quicktime',
    'video/x-m4v',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/x-msvideo',
    'video/webm',
  ],
  audio: [
    'audio/3gpp',
    'audio/3gpp2',
    'audio/aac',
    'audio/mp3',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/flac',
    'audio/wav',
    'audio/webm',
    'audio/x-aiff',
  ],
};

@Injectable()
export class MediaService {
  mediaUploader: FileUploader;
  thumbnailUploader: FileUploader;
  previewUploader: FileUploader;

  progressColor = '';
  uploadingPostMedia = false;
  uploadingText = '';
  uploadingProgress = 0;
  showProgress = false;
  postMedia: any;
  embeddedMedia: any;
  embeddedMediaItem: any;
  onCompletion: Function;

  currentPost: any;
  currentSection: string;
  currentType: string;

  keyExtractPattern = /http.*\/\/.*?\/(.*)\?.*/;

  constructor(private postService: PostService, private http: HttpClient) {
    this.mediaUploader = new FileUploader({
      disableMultipart: true,
      removeAfterUpload: true,
      autoUpload: false,
      method: 'PUT',
      authToken: getAuthToken(),
      authTokenHeader: 'Postd-Auth',
      isHTML5: true,
    });

    this.thumbnailUploader = new FileUploader({
      disableMultipart: false,
      removeAfterUpload: true,
      method: 'POST',
      authToken: getAuthToken(),
      authTokenHeader: 'Postd-Auth',
      isHTML5: true,
    });
    debug('initial thumbnailUploader', this.thumbnailUploader);

    this.previewUploader = new FileUploader({
      disableMultipart: true,
      removeAfterUpload: true,
      autoUpload: false,
      method: 'PUT',
      isHTML5: true,
      authToken: getAuthToken(),
      authTokenHeader: 'Postd-Auth',
      allowedMimeType: validMimeTypes['video'].concat(validMimeTypes['audio']),
    });
    /*
            List of allowedFileTypes values:

            application
            image
            video
            audio
            pdf
            compress
            doc
            xls
            ppt
        */
  }

  getMediaUploadUrl(
    postId: string,
    fileName: string,
    fileType: string,
    contentSection: string
  ): Promise<any> {
    const url =
      '/api/post/' +
      postId +
      '/' +
      contentSection +
      '/media/upload_url?filename=' +
      encodeURIComponent(fileName) +
      '&ctype=' +
      encodeURIComponent(fileType);

    return this.http.get<any>(url, {}).toPromise();
  }

  getDownloadUrl(key: string): string {
    // GET /post/{Post_id}/media/download?key=123
    if (!key) {
      return '';
    }
    return (
      window.location.origin +
      '/api/post/' +
      this.getKeyParts(key).postId +
      '/media/download?key=' +
      key
    );
  }

  uploadMedia(postId: string, contentSection: string, postType: string) {
    const uploader = contentSection === 'premium' ? this.mediaUploader : this.previewUploader;
    const promise = new Promise<{ mediaItem: any; file: File }>((resolve, reject) => {
      // uploader.setOptions({ method: 'PUT', disableMultipart: true, autoUpload: false });
      const fileToUpload = uploader.queue[0].file;
      if (!validMimeTypes[postType] || validMimeTypes[postType].indexOf(fileToUpload.type) > -1) {
        // get the upload URL
        this.getMediaUploadUrl(postId, fileToUpload.name, fileToUpload.type, contentSection)
          .then((resp) => {
            debug('getMediaUploadUrl', resp.upload_url);
            debug('uploadMedia file in queue', uploader.queue[0]);
            uploader.queue[0].url = resp.upload_url;
            uploader.onSuccessItem = (item, successResp, status, headers) => {
              debug('on file upload success', item, successResp, status, headers);
              resolve({ mediaItem: item, file: (fileToUpload as any).rawFile as File });
            };
            uploader.onErrorItem = (item, response, status, headers) => {
              debug('onErrorItem', item, response, status, headers);
              reject({ msg: 'error in uploading', item, response, status, headers });
            };
            uploader.queue[0].upload();
          })
          .catch((err) => {
            console.log('could not get the media upload url', fileToUpload, err);
            reject({ msg: 'could not get the media upload url', fileToUpload, err });
          });
      } else {
        uploader.clearQueue();
        reject({ msg: 'Invalid file type', fileToUpload, err: null });
      }
    });
    return promise;
  }

  verifyServerPremiumMediaUpdate(postId: string, postMediaItem: FileItem): Promise<any> {
    const self = this;
    const startCheck = Date.now();
    const maxAllowedTimeForServerUpdate = postMediaItem.file.size * 0.0001 + 20000; // 10 seconds plus 1 more second per 10MB
    const interval = 2000; // 2 seconds

    const promise = new Promise((resolve, reject) => {
      const checkingTimer = setInterval(checkServer, interval);
      function checkServer() {
        if (Date.now() - startCheck > maxAllowedTimeForServerUpdate) {
          clearInterval(checkingTimer);
          resolve(null);
        }
        self.postService.getPostInfo(postId, true).subscribe(
          (res) => {
            const returnedPost = new Post(res);

            debug('getPostInfo', returnedPost, postId, postMediaItem);
            if (returnedPost.premiumMedia.length > 0) {
              const lastPremiumMedia =
                returnedPost.premiumMedia[returnedPost.premiumMedia.length - 1];
              const collapsedFileName = self.stripName(postMediaItem.file.name);
              const keyParts = self.getKeyParts(lastPremiumMedia['key']);
              debug('keyparts', keyParts);
              if (lastPremiumMedia['statusCode'] === 'NOT_SUPPORTED') {
                clearInterval(checkingTimer);
                resolve(null);
              }
              if (
                keyParts.postId === postId &&
                collapsedFileName.startsWith(keyParts.fileName) &&
                // postMediaItem.file.type.endsWith(keyParts.type) &&
                keyParts.section === 'premium'
              ) {
                clearInterval(checkingTimer);
                resolve(returnedPost);
              }
            }
          },
          (err) => {
            console.log('could not get post while checking for server update', err);
          }
        );
      }
    });
    return promise;
  }

  setThumbnail(thumb: any, postId: string): Promise<any> {
    // set thumbnail to auto generated item
    debug('setThumbnail', thumb);
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const thumbUrl = thumb.download;
    let url: string = '/api/post/' + postId + '/thumbnail/image';
    url += '?url=' + thumbUrl;
    return this.http
      .post<any>(url, {
        headers,
      })
      .toPromise();
  }

  uploadThumbnail(postId: string) {
    this.thumbnailUploader.queue[0].url = '/api/post/' + postId + '/thumbnail/image';
    this.thumbnailUploader.options.autoUpload = false;
    this.thumbnailUploader.options.allowedFileType = ['image'];

    return new Observable<Post>((sub) => {
      this.thumbnailUploader.onCompleteItem = (item, resp, status, headers) => {
        debug('item on complete', item, status, headers);
        sub.complete();
        this.thumbnailUploader.clearQueue();
        this.thumbnailUploader.cancelAll();
      };
      this.thumbnailUploader.onSuccessItem = (item, resp, status, headers) => {
        try {
          const post: Post = JSON.parse(resp);
          debug('on thumbnail upload success', item, post, status, headers);
          if (post.status !== 400) {
            sub.next(post);
            // this.postChanges.thumbnail = true;
          } else {
            debug('file error', post);
            sub.error(THUMBNAIL_ERROR.WRONG_FILETYPE);
          }
        } catch (e) {
          sub.error(e);
        }
      };
      this.thumbnailUploader.onErrorItem = (item, resp, status, headers) => {
        debug('onErrorItem', item, resp, status, headers);
        try {
          const responseObj = JSON.parse(resp);
          switch (responseObj.code) {
            case 'INVALID_EMPTY_IMAGE':
              sub.error(THUMBNAIL_ERROR.INVALID_IMAGE);
              break;
            default:
              sub.error(THUMBNAIL_ERROR.UPLOAD_FAILED);
          }
        } catch (e) {
          sub.error(THUMBNAIL_ERROR.UNKNOWN);
        }
        sub.complete();
      };

      debug('in selectThumbnailModal', this.thumbnailUploader);
      this.thumbnailUploader.uploadAll();
    });
  }

  private getKeyParts(key: string): any {
    if (!key) {
      return {};
    }
    const keyParts = key.split('/');
    const parts = { postId: '', fileName: '', type: '', section: '' };
    if (keyParts.length < 3) {
      console.log('in getKeyParts, invalid key: ' + key);
    } else {
      parts.postId = keyParts[0];
      parts.section = keyParts[1];
      const nameParts = keyParts[2].split('.');
      parts.type = nameParts[nameParts.length - 1];
      parts.fileName = this.stripName(keyParts[2].replace('.' + parts.type, ''));
    }
    return parts;
  }

  private stripName(nm: string) {
    const stripped = nm.replace(/[^a-z0-9]/gi, '');
    return stripped;
  }

  private extractData(res: Response) {
    if (res.status !== 200) {
      throw new Error('Bad response status: ' + res.status);
    }
    // debug('extractData response', res);
    const body = res.json();
    if (body) {
      // TODO see about caching user data
      return body;
    }

    return {};
  }

  private setCurrentPost(post: any) {
    this.currentPost = post;
    debug('setCurrentPost', post);
  }

  private fileNamesMatch(test: string, uploadedName: string): Boolean {
    let matches = true;
    if (test.length !== uploadedName.length) {
      return false;
    }
    for (let i = 0; i < test.length; i++) {
      if (' _-'.indexOf(test[i]) < 0) {
        if (test[i] !== uploadedName[i]) {
          matches = false;
          break;
        }
      }
    }
    return matches;
  }
}
