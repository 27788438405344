import * as Debug from 'debug';
const debug = Debug('shared:PostService');

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { Observable, Subject, forkJoin, from, of, timer, throwError } from 'rxjs';
import { catchError, map, mergeMap, retryWhen } from 'rxjs/operators';

import { ChannelService } from './channel_service';
import { CacheService } from './cache_service';
import { LocationService } from './location_service';
import { PurchaseService } from './purchase_service';
import { TrackingService } from './tracking_service';
import { UserService } from './user_service';

import { Post } from '@shared/types/post';
import { Review } from '@shared/types/review';
import { Comment } from '@shared/types/comment';
import { Media, StaticMedia } from '@shared/types/media';
import { Channel } from '@shared/types/channel';

import { setPurchaseToken } from '@shared/interceptors/api.interceptor';

import { environment } from '@env/environment';

import * as _ from 'lodash';

export const DEFAULT_MAX_PRICE = 98;

export interface PaymentStatus {
  processor: number;
  currency: string;
  accounted: number;
  id: string;
  postId: string;
  channelId: string;
  buyerId: number;
  sellerId: number;
  publisherId: number;
  state: number;
  payType: number;
  price: number;
  commission_cc: number;
  usrEarns_cc: number;
  created: number;
  updated: number;
  ip_adress: string;
  download: number;
  pIdBuyerIdx: string;
}
export interface PurchaseStatus {
  active_subscription_id?: any;
  channel: Channel;
  hasAccess: boolean;
  payment?: PaymentStatus;
}

export interface IMinMax {
  min?: number;
  max?: number;
}

export interface PostQueryParameters {
  query: string;
  tag?: string;
  date?: IMinMax;
  rating?: IMinMax;
  price?: IMinMax;
  sort?: string;
  size?: number;
  fromOffset?: number;
  channelId?: string;
  type?: string;
  classification?: string;
  category?: string;
}

interface BreakpointMap {
  [key: number]: number;
}

@Injectable()
export class PostService {
  postCategories: string[];
  purchasedStatus: {
    [key: string]: {
      purchased: boolean;
      canDownload?: boolean;
    };
  };
  private filteredPosts = new Subject<Post[]>();

  constructor(
    protected _sanitizer: DomSanitizer,
    private locationService: LocationService,
    private channelService: ChannelService,
    private cacheService: CacheService,
    private trackingService: TrackingService,
    private purchaseService: PurchaseService,
    private userService: UserService,
    private http: HttpClient,
    @Inject(DOCUMENT) private _document,
    @Inject(PLATFORM_ID) private platformId
  ) {
    debug('PostService constructor');
    this.postCategories = [];
    this.purchasedStatus = {};

    // Clear purchase status when the user account changes
    this.userService.curUser$.subscribe(() => {
      this.postCategories = [];
      this.purchasedStatus = {};
    });
  }

  createNewChannelPost(id: string, name: string): Promise<Post> {
    return this.http
      .get<Post>('/api/channel/' + id + '/post/new' + (name ? `?name=${name}` : ''), {})
      .toPromise()
      .then(
        (resp) => this.processResponsePost(false)(resp),
        (err) => {
          throw err;
        }
      );
  }

  changePostChannel(postId: string, chanId: string) {
    // /api/post/POST_Id/channel/NEW_channelId
    const url = '/api/post/' + postId + '/channel/' + chanId;
    return this.http.post<any>(url, {}).toPromise();
  }

  /** deprecate this */
  updatePost(postId: string, urlStub: string, params: any): Promise<any> {
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const url: string = '/api/post/' + postId + '/' + urlStub;
    const options = _.keys(params).indexOf('content') >= 0 ? { responseType: 'text' } : {};
    if (_.keys(params).indexOf('content') >= 0) {
      return this.http.post(url, params, { responseType: 'text' }).toPromise();
    } else {
      return this.http.post(url, params).toPromise();
    }
    // Cache updated by updatePost caller by invoking this mergeAndUpdateCache after processing the response
  }

  updateShortUrl(postId: string, desiredShortUrl: string) {
    const url = `/api/post/${postId}/short-url/${desiredShortUrl}`;
    return this.http.post(url, {}).toPromise();
  }

  getByShortUrl(shortUrl: string) {
    const url = `/api/search/short-url/post/${shortUrl}`;
    return this.http.get<{ post: Post; channel: Channel }>(url).pipe(
      map((result) => {
        const post = new Post(result.post);
        post.channelInfo = result.channel;

        this.cacheService.setPostInfo(post.id, post);
        this.cacheService.setChannelInfo(result.channel.id, result.channel);

        return {
          post,
          channel: result.channel,
        };
      })
    );
  }

  // TBD RPH - this method needs to update the cached post looks like we need to do a
  // noCached getPostInfo to get the updated Post after the POST /post/id/rating is executed
  ratePost(postId: string, rating: number, review: any) {
    const token = this.cacheService.getPostPurchaseToken(postId);

    const payload = {
      rating,
    };
    if (token) {
      payload['token'] = token;
    }
    if (review) {
      payload['subject'] = review.subject;
      payload['comments'] = review.comments;
    }

    return this.http.post<Review>('/api/post/' + postId + '/rating', payload).toPromise();
  }

  commentOnPost(postId: string, commentBody: string, commentToReplyId?: string) {
    const payload = {
      comments: commentBody,
      parent: null,
    };
    if (commentToReplyId !== undefined) {
      payload.parent = commentToReplyId;
    }

    return this.http.post('/api/post/' + postId + '/comment', payload).toPromise();
  }

  getPostInfo(postId: string, refreshData = false, channelCache?): Observable<Post> {
    const self = this;

    return new Observable((observer) => {
      let cachedPost: Post = null;
      if (!refreshData) {
        cachedPost = this.cacheService.getPostInfo(postId);
        debug('cachedPost', cachedPost);
      }
      if (cachedPost && !refreshData) {
        observer.next(this.processResponsePost(false)(cachedPost));
        observer.complete();
      } else {
        const blacklist = this.cacheService.getBlacklist();
        if (blacklist[postId]) {
          observer.error(blacklist[postId]);
        } else {
          this.http.get<Post>('/api/post/' + postId + '/info', {}).subscribe(
            (resp) => {
              observer.next(this.processResponsePost(false)(resp));
              observer.complete();
            },
            (err) => {
              if (err.status === 404) {
                if (this.cacheService) {
                  this.cacheService.clearCachedPost(postId);
                  this.cacheService.blacklistPost(postId, err);
                }
              }
              observer.error(err);
            }
          );
        }
      }
    });
  }

  getAllPostInfo(postIds: string[]): Observable<Post[]> {
    const channelCache = {};
    return forkJoin(
      postIds.map((postId: string) =>
        this.getPostInfo(postId, false, channelCache).pipe(catchError((err) => from([null])))
      )
    );
  }

  getPostRatings(postId: string): Promise<Review[]> {
    return this.http
      .get<Review[]>('/api/post/' + postId + '/ratings', {})
      .pipe(
        catchError((err) => {
          console.error('could not get ratings for post ' + postId);
          return from([[]]);
        })
      )
      .toPromise();
  }

  getPostComments(postId: string): Promise<Comment[]> {
    return this.http
      .get<Comment[]>('/api/post/' + postId + '/comments', {})
      .pipe(
        catchError((err) => {
          console.error('could not get comments for post ' + postId);
          return from([[]]);
        })
      )
      .toPromise();
  }

  getOwnPostRating(postId: string): Promise<Review> {
    return this.http
      .get<Review>('/api/post/' + postId + '/rating', {})
      .toPromise()
      .then(
        (resp) => {
          if (!_.isEmpty(resp)) {
            return resp;
          } else {
            return null;
          }
        },
        (err) => {
          if (err.status === 403) {
            // Haven't purchased, NBD
            return null;
          }
          throw err;
        }
      );
  }

  setCommentsEnabled(postId: string, enabled: boolean): Observable<any> {
    const url = '/api/post/' + postId + '/comments/' + (enabled ? 'enabled' : 'disabled');
    return this.http.put<any>(url, {});
  }

  loadPostLead(post: Post, noCache = false, validatePurchase = false) {
    let promises;
    const cachedPostLead = noCache ? null : this.cacheService.getPostLead(post.id);

    if (cachedPostLead) {
      promises = [Promise.resolve(cachedPostLead)];
    } else {
      promises = [
        this.http
          .get('/api/post/' + post.id + '/lead/content', { responseType: 'text' })
          .toPromise(),
        /*this.http.get( '/api/post/' + postId + '/lead/media/list' , {})
                .map(this.extractData)
                .toPromise()*/
      ];
    }

    return new Promise<Post>((resolve, reject) => {
      Promise.all(promises)
        .then((results) => {
          debug('lead content response', results);
          post.leadContent = this._sanitizer.bypassSecurityTrustHtml(
            this.processBase64(results[0] as string)
          );
          this.cacheService.setPostLead(post.id, results[0]);
          // post.leadMediaList = results[1];
          if (!validatePurchase) {
            resolve(post);
          } else {
            /*return this.http.get( '/api/post/'+ post.id +'/purchase/validate' , {})
            .map(this.extractData)
            .toPromise().then((response) => {
              console.log(response);
              return combined;
            }, (err) => {
              throw err;
            })*/
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadPostPremium(post: Post, token?: string) {
    if (!token) {
      token = this.cacheService.getPostPurchaseToken(post.id);
    } else {
      setPurchaseToken(token, post.id, null);
    }

    debug('loadPostPremium post and token', post, token);

    return this.http
      .get('/api/post/' + post.id + '/premium/content' + (token ? '?t=' + token : ''), {
        responseType: 'text',
      })
      .pipe(
        map((postContent) => {
          post.premiumContent = this._sanitizer.bypassSecurityTrustHtml(
            this.processBase64(postContent as string)
          );
          this.purchasedStatus[post.id] = { purchased: true };
          return post;
        }),
        catchError((err) => {
          if (err.status !== 402) {
            console.log('error in post promise all', err);
            post.status = err.status;
            return throwError(err);
          } else {
            post.purchased = false;
          }

          return of(post);
        })
      )
      .toPromise();
  }

  preparePurchasePost(post: Post, download = false) {
    const self = this;
    const post_url = self.locationService.getCurrExternalUrl();
    let api_url = '/api/post/' + post.id + '/purchase/token';

    // note this url is only needed for localhost dev so that the backend sends you
    // signed token that includes the localhost url in the signed token
    if (post_url) {
      debug('local post_url', post_url);
      api_url += '?url=' + post_url;
      if (download) {
        api_url += '&download=1';
      }
    } else if (download) {
      api_url += '?download=1';
    }
    debug('preparePurchasePost url', api_url);
    // Get the purchase token, then map that to a prepared purchase instance
    return this.http.get<{ token: string }>(api_url, {}).pipe(
      catchError((err) => throwError({ code: 'TOKEN_FAIL' })),
      mergeMap((response) =>
        this.purchaseService.preparePurchase<Post>(response.token, {
          catchError: () =>
            // If the purchase fails, we have to check if the purchase window disconnected and the purchase actually completed

            // When verifying purchase, wait until the browser has focus. Otherwise the purchase probably isn't complete
            timer(500).pipe(
              mergeMap(() => {
                if (this._document.hasFocus()) {
                  return this.checkPostPurchaseSucceeded(post, response.token);
                } else {
                  if (isPlatformBrowser(this.platformId)) {
                    return new Observable<FocusEvent>((observer) => {
                      window.onfocus = (event) => {
                        observer.next(event);
                        observer.complete();
                      };
                    }).pipe(mergeMap(() => this.checkPostPurchaseSucceeded(post, response.token)));
                  }
                }
              })
            ),
          flatMap: (paymentCompletedToken) => {
            // If the purchase completed, save the purchase token for future viewing, and load the full premium post object
            if (paymentCompletedToken !== 'complete') {
              self.cacheService.setPostPurchaseToken(post.id, paymentCompletedToken);
            }
            this.purchasedStatus[post.id] = { purchased: true };

            self.trackingService.trackPid('nzsmm', {
              tw_sale_amount: post.price,
              tw_order_quantity: 0,
            });
            post.purchaseCount++;
            return from(self.loadPostPremium(post, paymentCompletedToken));
          },
        })
      )
    );
  }

  private checkPostPurchaseSucceeded(post: Post, token: string) {
    const maxRetryPurchaseCheck = 1;
    const loggedIn = this.userService.curUser && this.userService.curUser.id;
    let url;

    // If not logged in, we also have to send the purchase token to the verify token endpoint instead
    if (loggedIn) {
      url = `/api/post/${post.id}/purchase/verify-user`;
    } else {
      url = `/api/post/${post.id}/purchase/verify-token/${token}`;
    }

    return this.http
      .get<any>(url)
      .pipe(
        mergeMap((result) => {
          if (!result.hasAccess) {
            return throwError('Could not verify purchase');
          }
          return of('complete');
        })
      )
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((val, i) => {
              if (i >= maxRetryPurchaseCheck) {
                return throwError({
                  code: 'TRANSACT_FAIL',
                  msg: 'Too many retries trying to check purchase',
                });
              }
              // retry in 0.5 second
              return timer(500);
            })
          )
        )
      );
  }

  checkPostPurchased(postId: string, forceRefresh?: boolean, loggedIn?: boolean): Promise<boolean> {
    if (this.userService.curUser && this.userService.curUser.admin) {
      return Promise.resolve(true);
    }

    if (!forceRefresh && this.purchasedStatus[postId] !== undefined) {
      return Promise.resolve(this.purchasedStatus[postId].purchased);
    }
    if (!forceRefresh) {
      const cachedToken = this.cacheService.getPostPurchaseToken(postId);
      if (cachedToken) {
        this.purchasedStatus[postId] = {
          purchased: true,
          canDownload: false,
        };
        return Promise.resolve(true);
        // If they have a token in their cache, they probably purchased it.
        // But it's not a guarantee, so be prepared to handle 402 from loadPostPremium
      } else if (loggedIn === false) {
        return Promise.resolve(false);
      }
    }

    if (!this.userService.curUser || !this.userService.curUser.id) {
      // At this stage if we don't have a cached token and the user is not logged in then the user does not have access.
      // But don't save the purchase status so the user can still purchase
      return Promise.resolve(false);
    }

    return this.http
      .get<PurchaseStatus>('/api/post/' + postId + '/purchase/status')
      .pipe(catchError(() => of({ hasAccess: false } as PurchaseStatus)))
      .pipe(
        map((result) => {
          this.purchasedStatus[postId] = {
            purchased: result.hasAccess,
            canDownload: !!result.payment?.download,
          };
          return result.hasAccess;
        })
      )
      .toPromise();
  }

  checkDownloadAccess(postId: string) {
    if (this.purchasedStatus[postId] && this.purchasedStatus[postId].canDownload !== undefined) {
      return Promise.resolve(this.purchasedStatus[postId].canDownload);
    } else {
      return this.checkPostPurchased(postId, true).then((hasAccess) => {
        if (hasAccess) {
          return this.purchasedStatus[postId]?.canDownload;
        } else {
          return false;
        }
      });
    }
  }

  getPost(postId: string, noCache = false): Promise<Post> {
    return new Promise<Post>((resolve, reject) => {
      this.getPostInfo(postId, noCache).subscribe(
        (post) => {
          Promise.all([
            this.loadPostLead(post, noCache).catch((origPost) => origPost),
            this.loadPostPremium(post).catch((origPost) => origPost),
            // this.http.get( '/api/post/' + postId + '/lead/media/list' , {})
            //         .map(this.extractData)
            //         .toPromise(),
            // this.http.get( '/api/post/' + postId + '/premium/media/list' , {})
            //         .map(this.extractData)
            //         .toPromise()
          ])
            .then(() => {
              debug('promise all results', post);
              post.status = 1;
              post = this.processResponsePost(false)(post);
              resolve(post);
            })
            .catch((err) => {
              console.log('error in post promise all', err);
              reject(err);
            });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getMediaItem(post: Post, lead?: boolean): Media {
    let foundItem: StaticMedia = null;
    if (post) {
      let postMedia;
      if (lead) {
        postMedia = post.leadMedia;
      } else {
        postMedia = post.premiumMedia;
      }

      if (postMedia && postMedia.length > 0) {
        for (let i = postMedia.length - 1; i >= 0; i--) {
          if (postMedia[i].duration) {
            foundItem = postMedia[i];
            break;
          }
        }
        if (!foundItem && postMedia.length >= 1) {
          foundItem = postMedia[0];
        }
      }
    }
    return foundItem;
  }

  deleteMediaItem(postId: string, mediaKey: string) {
    // DELETE /api/post/ID/media      then pass 'key'
    const payload = { key: mediaKey };
    const options = { params: payload };
    const url = '/api/post/' + postId + '/media';
    return this.http.delete(url, options).toPromise();
  }

  getNewestPosts(options?: { tag?: string; maxPrice?: number }): Promise<Post[]> {
    console.log('get newest');
    debug('getNewestPosts', 'tag', options?.tag);

    // Only need to get first 25 posts on SSR, saves on localstorage usage and transfer speed
    const pageSize = isPlatformBrowser(this.platformId) ? 50 : 25;

    return this.queryPosts({
      query: '',
      tag: options?.tag,
      price: options?.maxPrice ? { max: options.maxPrice, min: 0 } : undefined,
      size: pageSize,
    });
  }

  getFeaturedPosts(filter?: { type?: string; classification?: string }): Promise<Post[]> {
    let url = '/api/search/posts/featured';
    if (filter) {
      const { type, classification } = filter;
      if (classification && classification !== 'all') {
        url = url + '/classification-' + classification;
      } else if (type && type !== 'all') {
        url = url + '/kind-' + type;
      }
    }

    return this.http
      .get<Post[]>(url)
      .toPromise()
      .then(
        (resp) =>
          resp
            .map(this.processResponsePost(false, {}), this)
            .filter((post) => post.featured !== 0)
            .sort((a, b) => a.featured - b.featured),
        (err) => {
          throw err;
        }
      );
  }

  setPostAsFeatured(chanId: string, postId: string) {
    return this.http
      .post<Post[]>('/api/channel/' + chanId + '/posts/featured/' + postId, {})
      .toPromise();
  }

  removePostAsFeatured(chanId: string, postId: string) {
    return this.http
      .delete<Post[]>('/api/channel/' + chanId + '/posts/featured/' + postId, {})
      .toPromise();
  }

  getChannelPosts(
    chanId: string,
    fromOffset?: number,
    size?: number,
    query?: string,
    tags?: string,
    sort?: string
  ): Promise<any> {
    let url =
      '/api/channel/' +
      chanId +
      '/posts/list?fromOffset=' +
      fromOffset.toString() +
      '&size=' +
      size.toString();
    url += fromOffset || size || query + tags + sort !== '' ? '?' : '';
    url += fromOffset ? '&fromOffset=' + fromOffset.toString() : '';
    url += size ? '&size=' + size.toString() : '';
    url += query !== '' ? '&q=' + encodeURI(query) : '';
    url += tags !== '' ? '&tag=' + encodeURI(tags) : '';
    url += sort !== '' ? '&sort=' + encodeURI(sort) : '';
    return this.http
      .get<any>(url, {})
      .toPromise()
      .then(
        (resp) => ({
          total: resp.total,
          posts: resp.posts.map(this.processResponsePost(true, {}), this),
        }),
        (err) => {
          throw err;
        }
      );
  }

  getCategoryPosts(categoryName: string): Promise<Post[]> {
    // RPH TBD - modify url below to use global search query
    // GET /api/search/posts/simple/query
    return this.http
      .get<{ posts: Post[] }>('/api/search/posts/category/' + categoryName + '?getDetails=1', {})
      .toPromise()
      .then(
        (resp) => resp.posts.map(this.processResponsePost(true, {}), this),
        (err) => {
          throw err;
        }
      );
  }

  putName(postId: string, name: string): Promise<any> {
    const url: string = '/api/post/' + postId + '/name';
    return this.http.post<any>(url, { name }).toPromise();
  }

  putContent(postId: string, type: 'premium' | 'lead', content: string): Promise<any> {
    const url = `/api/post/${postId}/${type}/content`;
    return this.http.post(url, { content }, { responseType: 'text' }).toPromise();
  }

  putTags(postId: string, tagList: string): Promise<any> {
    // let headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const url: string = '/api/post/' + postId + '/tags';
    return this.http.put<any>(url, { tags: tagList }).toPromise();
    // Cache updated by putTags caller by invoking this mergeAndUpdateCache after processing the response
  }

  putPrice(postId: string, price: number): Observable<any> {
    const url: string = '/api/post/' + postId + '/price/' + price.toString();
    return this.http.put(url, {});
    // Cache updated by putPrice caller by invoking this mergeAndUpdateCache after processing the response
  }

  putPriceDl(postId: string, price: number): Observable<any> {
    const url: string = '/api/post/' + postId + '/price_dl/' + price.toString();
    return this.http.put(url, {});
    // Cache updated by putPrice caller by invoking this mergeAndUpdateCache after processing the response
  }

  putAgeRange(postId: string, age: number) {
    // POST  /api/post/POST_ID/min-age/{MIN_AGE}
    const url = '/api/post/' + postId + '/min-age/' + age;
    return this.http.post<Post[]>(url, {}).toPromise();
  }

  putGeofencing(postId: string, countries: string[]) {
    const url = '/api/post/' + postId + '/countries';
    if (countries.length === 0) {
      countries = ['ALL'];
    }
    return this.http
      .put<Post[]>(url, {
        countries,
      })
      .toPromise();
  }

  putPostState(postId: string, state: 'PUBLIC' | 'HIDDEN' | 'TAKEN_DOWN') {
    return this.http.post('api/post/' + postId + '/state/' + state, {});
  }

  deletePost(postId: string) {
    const url: string = '/api/post/' + postId;
    return this.http.delete(url, {});
  }

  getPostMediaList(postId: string) {
    // TBD - this needs to be expanded to give both lead and premium media
    return this.http.get<Media[]>('/api/post/' + postId + '/lead/media/list', {}).toPromise();
  }

  getMediaCloudUrl(perplayUrl: string): Promise<string> {
    return this.http.get<string>(perplayUrl, {}).toPromise();
  }

  processBase64(content: string): string {
    let res = '';
    let imgURL = '';
    if (content) {
      // res = content;
      // res = content.replace(/src="data:/g,'[src]="data:');

      const rExpr = /([\s\S]*)(<img )([\s\S]*)(src="data:)([\s\S]*)(base64,)([\s\S]*?)"([\s\S]*)/;
      const matchRes = content.match(rExpr);
      if (matchRes) {
        imgURL = 'data:' + matchRes[5] + matchRes[6] + matchRes[7].replace(/ /g, '+');
        res += matchRes[1] + matchRes[2] + matchRes[3] + 'src="' + imgURL + '"';
        res += matchRes[8];
      } else {
        res = content;
      }
    }
    return res;
  }

  queryPosts({
    query,
    tag = '',
    date = {},
    rating = {},
    price = {},
    size = null,
    fromOffset = null,
    channelId = '',
    sort = 'dateDesc',
    type = '',
    classification = '',
    category = '',
  }: PostQueryParameters) {
    debug('createPostsQueryString tag is', tag);
    debug('createPostsQueryString query is', query);
    const allowedSorts: string[] = [
      'dateAsc',
      'dateDesc',
      'createdDateDesc',
      'createdDateAsc',
      'priceAsc',
      'priceDesc',
      'ratingAsc',
      'ratingDesc',
      'purchaseCountAsc',
      'purchaseCountDesc',
    ];
    const isValidSort = allowedSorts.indexOf(sort);

    const tags = [];
    if (type) {
      tags.push('kind-' + type);
    }
    if (classification) {
      tags.push('classification-' + classification);
    }
    if (category && category !== 'all') {
      tags.push('category-' + category);
    }

    let qString = '?q=' + (typeof query !== 'undefined' ? query : '');
    qString += tag.length > 0 ? '&tag=' + encodeURIComponent(tag) : '';
    qString += date.min !== undefined ? '&fromDate=' + date.min : '';
    qString += date.max ? '&toDate=' + date.max : '';
    qString += rating.min !== undefined ? '&ratingMin=' + rating.min : '';
    qString += rating.max ? '&ratingMax=' + rating.max : '';
    if (price.min !== undefined || price.max !== undefined) {
      qString += '&priceMin=' + (price.min || 0);
      qString += price.max !== undefined ? '&priceMax=' + price.max : '';
    }
    qString += size ? '&size=' + size : '';
    qString += fromOffset ? '&fromOffset=' + fromOffset : '';
    qString += channelId ? '&channelId=' + channelId : '';
    qString += isValidSort >= 0 ? '&sort=' + sort : '';
    qString += tags.length > 0 ? '&tag=' + tags.join(',') : '';
    debug('globalQueryString', qString);
    const url = '/api/search/posts/simple/query' + qString + '&getDetails=1';
    console.log('LOADING', url);
    return this.http
      .get(url, {})
      .toPromise()
      .then(
        (resp: any) => {
          const res = resp['posts'].map(this.processResponsePost(true, {}), this);
          return res;
        },
        (err) => {
          throw err;
        }
      );
  }

  doSimpleSearch(
    query?: string,
    channelId?: string,
    tag?: string,
    fromOffset?: string,
    size?: string
  ): Observable<Post[]> {
    const data = {
      q: query ? query : '',
      channelId: channelId ? channelId.trim() : '',
      tag: tag ? tag.trim() : '',
      fromOffset: fromOffset ? fromOffset : '0',
      size: size ? size : '',
    };

    const params: HttpParams = new HttpParams()
      .set('q', data.q)
      .set('channelId', data.channelId)
      .set('tag', data.tag)
      .set('fromOffset', data.fromOffset)
      .set('size', data.size)
      .set('getDetails', 'true');

    const channelCache = {};

    return this.http
      .get<{ posts: Post[] }>('/api/search/posts/simple/query', { params })
      .pipe(
        map((result) =>
          result.posts.map((post) => this.processResponsePost(true, channelCache)(post))
        )
      );
  }

  setSimpleSearchResult(results: Post[]) {
    this.filteredPosts.next(results);
  }

  getSimpleSearchResult() {
    return this.filteredPosts.asObservable();
  }

  getPostCategories() {
    return this.postCategories;
  }

  mergeAndUpdateCache(postUpdate: Post) {
    // postUpdate must include id and any other Post properties
    const cached = this.cacheService.getPostInfo(postUpdate.id);
    if (!cached && postUpdate.state === 'PUBLIC') {
      this.cacheService.setPostInfo(postUpdate.id, postUpdate);
    } else {
      _.assignWith(cached, postUpdate, (objValue, srcValue, key, object, source) =>
        typeof srcValue === 'undefined' ? objValue : srcValue
      );
      this.cacheService.setPostInfo(postUpdate.id, cached);
    }
  }

  clearPostCache(postId: string) {
    this.cacheService.clearCachedPost(postId);
  }

  public flagPost(postId: string, reason: string, explanation: string): Promise<any> {
    const params = {
      reason,
      comments: explanation,
    };

    const url: string = '/api/post/' + postId + '/flag';
    return this.http.post<any>(url, params).toPromise();
  }

  flagComment(
    commentId: string,
    postId: string,
    reason: string,
    explanation: string
  ): Promise<any> {
    const params = {
      reason,
      comments: explanation,
    };
    // POST /api/post/{Post_id}/comment/{commentId}/flag
    const url: string = '/api/post/' + postId + '/comment/' + commentId + '/flag';
    return this.http.post<any>(url, params).toPromise();
  }

  deleteComment(postId: string, commentId: string): Observable<any> {
    return this.http.delete<Post>(`/api/post/${postId}/comment/${commentId}/remove`, {});
  }

  setLocation(postId: string, location: any): Promise<any> {
    const url = '/api/post/' + postId + '/location/lat_lng';
    const data = {
      lat: location.lat,
      lng: location.lng,
    };
    // event can be a function sometimes
    if (typeof location.lat === 'function') {
      data.lat = location.lat();
    }

    if (typeof location.lng === 'function') {
      data.lng = location.lng();
    }
    return this.http.post<any>(url, data, {}).toPromise();
  }

  setAddress(postId: string, address: string): Promise<any> {
    const url = '/api/post/' + postId + '/location/address/' + encodeURIComponent(address);
    return this.http.post<any>(url, {}, {}).toPromise();
  }

  getCountryList() {
    const url = '/api/geographic/countries/dict';
    return this.http.get<any>(url);
  }

  getPostThumbnail(post: Post, targetWidth?: number | BreakpointMap): string | null {
    if (!post) {
      return '';
    }
    if (!targetWidth) {
      targetWidth = 300;
    }
    let thumbnailUrl: string;

    if (post.thumbnails) {
      const availableResolutions = Object.keys(post.thumbnails);
      let chosenResolution = 0;

      if (typeof targetWidth === 'number') {
        if (isPlatformBrowser(this.platformId)) {
          targetWidth = targetWidth * window.devicePixelRatio;
        }
        // get the smallest resolution thumb that's bigger than the target size
        while (availableResolutions.length > 0 && chosenResolution < targetWidth) {
          chosenResolution = parseInt(availableResolutions.shift(), 10);
        }
      } else if (isPlatformBrowser(this.platformId)) {
        // Get the smallest breakpoint that the current browser window fits into
        const availableBreakpoints = Object.keys(targetWidth);
        const actualWindowResolution = window.innerWidth * window.devicePixelRatio;
        let chosenBreakpoint = 0;
        do {
          chosenBreakpoint = parseInt(availableBreakpoints.shift(), 10);
        } while (
          availableBreakpoints.length > 0 &&
          parseInt(availableBreakpoints[0], 10) < actualWindowResolution
        );

        const calculatedTargetWidth = targetWidth[chosenBreakpoint];

        // get the smallest resolution thumb that's bigger than the target size
        while (availableResolutions.length > 0 && chosenResolution < calculatedTargetWidth) {
          chosenResolution = parseInt(availableResolutions.shift(), 10);
        }
      }

      thumbnailUrl = post.thumbnails[chosenResolution];
    } else if (post.thumbnailImage) {
      thumbnailUrl = post.thumbnailImage;
    } else {
      if (post.channelInfo && post.channelInfo.iconImageUrl) {
        thumbnailUrl = post.channelInfo.iconImageUrl;
      } else {
        thumbnailUrl = null;
      }
    }

    return thumbnailUrl;
  }

  loadChannelForPost(post: Post) {
    return this.channelService.getChannelInfo(post.channelId).pipe(
      catchError((err) =>
        from([
          {
            name: post.channelId,
          } as Channel,
        ])
      ),
      map((channelInfo) => {
        post.channelInfo = channelInfo as Channel;
        return post;
      })
    );
  }

  private processResponsePost(
    deferChannelLoad: boolean,
    channelCache?: { [key: string]: Promise<Channel> }
  ) {
    return (post: Post): Post => {
      const loggedIn = this.userService.curUser && this.userService.curUser.id;

      if (!environment.test) {
        debug('processResponsePost', post);
      }
      this.addToCategories(post['tags']);
      this.mergeAndUpdateCache(post);

      if (loggedIn && post.price === 0) {
        this.purchasedStatus[post.id] = { purchased: true };
      }
      if (this.purchasedStatus[post.id]?.purchased === true) {
        post.purchased = true;
      }
      return this.createPostObject(deferChannelLoad, post, channelCache);
    };
  }

  private createPostObject(
    deferChannelLoad,
    options,
    channelCache?: { [key: string]: Promise<Channel> }
  ) {
    const newPost = new Post(options);
    if (newPost.channelId && !deferChannelLoad) {
      this.loadChannelForPost(newPost);
    }
    return newPost;
  }

  private addToCategories(tags: string[]) {
    if (tags && tags.length > 0) {
      tags.forEach((t) => {
        if (this.postCategories.indexOf(t) < 0) {
          this.postCategories.push(t);
          this.postCategories.sort();
        }
      });
    }
  }

  public getSinglePurchaseToken(postId: string | number): Promise<{ token: string; uid: string }> {
    return this.http
      .get<{ token: string; uid: string }>(`/api/post/${postId}/purchase/token`)
      .toPromise();
  }

  public verifyPurchaseToken(postId: string, token: string): Promise<any> {
    return this.http.get<any>(`/api/post/${postId}/purchase/verify-token/${token}`).toPromise();
  }

  urlifyName(name: string) {
    let str = name.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const fromStr = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const toStr = 'aaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = fromStr.length; i < l; i++) {
      str = str.replace(new RegExp(fromStr.charAt(i), 'g'), toStr.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  }
}
