// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
declare function require(name: string);

import { meta } from './meta';
let buildInfo;
try {
  buildInfo = require('./buildInfo');
} catch (err) {
  buildInfo = meta.buildInfo;
}
const proxy = require('../../proxies/proxy.conf.json');

export const environment = {
  apiServer: proxy['/api'].target,
  version: buildInfo.version,
  buildDate: buildInfo.buildDate,
  enableServiceWorker: false,
  production: false,
  name: 'default',
  transactGroupId: 5203720654553088,
  transactBaseUrl: 'https://test.xsact.com',
  postdBaseUrl: 'https://postd.xsact.com',
  test: false,
  meta,
};
