import { Playlist } from './../types/playlist';
import { PlaylistService } from './playlist_service';
import { DOCUMENT } from '@angular/common';
import * as Debug from 'debug';
const debug = Debug('shared:MetaService');

import { Inject, Injectable, SecurityContext } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { PostService } from './post_service';
import { ChannelService } from './channel_service';
import { LocationService } from './location_service';

import { Channel } from '../types/channel';
import { Post } from '../types/post';

import { environment } from '@env/environment';
import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class MetaService {

    keywords: MetaDefinition;
    description: MetaDefinition;

    title: string;
    postdLogo: string;
    postdLogoSquare: string;

    constructor(
        public meta: Meta,
        public pageTitle: Title,
        private postService: PostService,
        private channelService: ChannelService,
        private playlistService: PlaylistService,
        private domSanitizer: DomSanitizer,
        private locationService: LocationService,
        private route: Router,
        @Inject(DOCUMENT) private document: Document) {

        this.postdLogo = this.postdLogoSquare = environment.postdBaseUrl + '/assets/images/postd_tm_logo/square/postd.png';
        this.initialize();

        this.route.events.subscribe( evt => {
            if (evt instanceof NavigationEnd || evt.constructor.name === 'NavigationEnd') {
                this.clearSpecialTags();
                this.setMetaPropertyTag('og:url', this.locationService.getCurrExternalUrl());
                this.setMetaPropertyTag('twitter:url', this.locationService.getCurrExternalUrl());
                this.setMetaPropertyTag('og:site_name', 'postd.io');
                this.setMetaPropertyTag('og:type', 'website');
                this.setMetaPropertyTag('og:image', this.postdLogo);
                this.setMetaPropertyTag('og:image:width', '421');
                this.setMetaPropertyTag('og:image:height', '421');
                this.setMetaPropertyTag('twitter:image', this.postdLogoSquare);
                this.setMetaPropertyTag('twitter:image:width', '421');
                this.setMetaPropertyTag('twitter:image:height', '421');
                if (evt['url'].startsWith('/info/intro-creators')) {
                  this.setTitle('Postd for Video Creators');
                  this.setDescription('DON\'T SETTLE FOR A FRACTION OF AD REVENUE. '
                      + 'Set your own price and take home 86% of the revenue');
                } else if (evt['url'].startsWith('/info/intro-make-more-money')) {
                  this.setTitle('Make more money from your videos.');
                  this.setDescription('Set your own price and take home 86% of the revenue');
                } else if (evt['url'].startsWith('/info/intro-short-form')) {
                  this.setTitle('Make more money from short-form videos.');
                  this.setDescription('Set your own price and take home 86% of the revenue');
                } else if (evt['url'].startsWith('/info/creators')) {
                  this.setTitle('Postd for Video Creators');
                  this.setDescription(
                      'Don\'t settle for a fraction of ad revenue. Don\'t let advertisers or ad platforms set the value of your work.');
                } else if (evt['url'].startsWith('/info/video-short-form-monetization')) {
                  this.setTitle('The Real Way to Make Money from Short-Form Video');
                  this.setDescription(
                        'Postd is a new public media marketplace, '
                        + ' designed from the ground up to help creators earn significantly more than with YouTube, Facebook, or TikTok '
                        + ' — without relying on ads. ');
                } else if (evt['url'].startsWith('/info/promo')) {
                          this.setTitle('postd.io - create a channel and earn up to $1000');
                          this.setDescription('Postd provides a marketplace for all forms of digital media, without ' +
                              'relying on advertising revenue. We\'ll give $100 to any YouTube or Vimeo ' +
                              'artist who has at least 1000 subscribers, to create a Postd channel and ' +
                              'post at least two 1-3 minute videos or ten ~10-second videos. ' +
                              'The owner of the channel with the most views at the end of October. 31, ' +
                              '2018, gets a $1000 bonus, plus 75% of all revenue that their channel ' +
                              'creates over $100. The next four channels get an extra $500 each.');
                      } else if (evt['url'] === '/' || evt['url'].startsWith('/info')) {
                } else if (evt['url'] === '/' || evt['url'].startsWith('/info')) {
                    this.setTitle('postd.io - watch, listen, read');
                    this.setDescription(environment.meta.description);
                } else if (evt['url'].indexOf('/post/') >= 0 || evt['url'].indexOf('/embed') >= 0 || evt['url'].indexOf('/p/') >= 0) {
                    let getPost: Observable<Post> | undefined;
                    debug('post url', evt['url']);

                    if (evt['url'].indexOf('/p/') >= 0) {
                        const postShortId = this.getId(evt['url'], 'p');
                        debug('postShortId', postShortId);
                        if (postShortId !== '') {
                            getPost = this.postService.getByShortUrl(postShortId).pipe(map(res => res.post));
                        }
                    } else {
                        const postId = this.getId(evt['url'], 'post');
                        debug('postId', postId);
                        if (postId !== '') {
                            getPost = this.postService.getPostInfo(postId);
                        }
                    }

                    if (getPost) {
                        debug('getPost Set');
                        getPost
                        .subscribe( (post) => {
                            const title = post.name + ' - postd.io';
                            this.setTitle(title);
                            const url = environment.apiServer
                              + '/api/services/oembed?url='
                              + encodeURIComponent(environment.apiServer + '/' + post.urlPath);
                            this.appendLinkTag('alternate',
                              'application/json+oembed', url, title);

                            this.postService.loadPostLead(post)
                            .then( (p: Post) => {
                                // update the description tags
                                this.setPostTags(p);
                            }).catch( err => {
                                console.log('post lead not found by Meta:', post.id, err);
                            });
                        }, err => {
                            console.log('post not found by Meta: ', err);
                        });
                    } else {
                        debug('getPost NOT Set');
                    }
                } else if (evt['url'].indexOf('/channel/') >= 0 || evt['url'].indexOf('/c/') >= 0) {
                    let getChannel: Observable<Channel> | undefined;

                    if (evt['url'].indexOf('/c/') >= 0) {
                        const channelShortId = this.getId(evt['url'], 'c');
                        if (channelShortId !== '') {
                            getChannel = this.channelService.getByShortUrl(channelShortId);
                        }
                    } else {
                        const channelId = this.getId(evt['url'], 'channel');
                        if (channelId !== '') {
                            getChannel = this.channelService.getChannelInfo(channelId);
                        }
                    }

                    if (getChannel) {
                        getChannel
                        .subscribe( channel => {
                            this.setTitle(channel.name + ' - postd.io');
                            this.setChannelTags(channel);
                        });
                    }

                } else if (evt['url'].indexOf('/playlist/') >= 0) {
                    const playlistId = this.getId(evt['url'], 'playlist');
                    if (playlistId !== '') {
                        this.playlistService.getPlaylist(playlistId)
                        .subscribe( playlist => {
                            if (playlist.postIds.length > 0) {
                                const postId = playlist.postIds[0];
                                this.postService.getPostInfo(postId)
                                    .pipe(mergeMap(post => this.postService.loadPostLead(post)))
                                    .subscribe(post => {
                                        this.setTitle(playlist.name + ' - postd.io');
                                        this.setPlaylistTags(post, playlist);
                                    }, err => {
                                        console.log('post not found by Meta:', postId, err);
                                    });
                            }
                        });
                    }

                } else if (evt['url'].indexOf('/search') >= 0) {
                    this.setTitle('Search - postd.io');
                    this.setMetaPropertyTag('og:type', 'website');
                } else {
                    this.initialize();
                    this.setTitle(environment.meta.defaultTitle);
                    this.setKeywords(environment.meta.keywords);
                    this.setDescription(environment.meta.description);
                }

            }
        });
    }

    initialize() {
        const prevDescriptionTags = this.meta.getTags('name="description"');
        if (prevDescriptionTags.length <= 0) {
            // Generally seems like the og image is cropped square more often than not...
            // this.postdLogo = this.locationService.getCurrExternalUrl() + '/assets/images/postd_tm_logo/social-rectangle/postd.png';
            this.postdLogo = this.postdLogoSquare = this.locationService.getHost() + '/assets/images/postd_tm_logo/square/postd.png';
            this.title = environment.meta.defaultTitle;
            this.description = {
                name: 'description',
                content: environment.meta.description
            };
            this.keywords = {
                name: 'keywords',
                content: environment.meta.keywords
            };
            this.setTitle(this.title);
            this.setKeywords(environment.meta.keywords);
            this.setDescription(environment.meta.description);
        }
    }

    setTitle( title: string) {
        debug('setTitle', title);
        this.title = title;
        this.pageTitle.setTitle(this.title);
        this.setMetaPropertyTag('og:title', this.title);
        this.setMetaPropertyTag('twitter:title', this.title);
    }

    setKeywords( keywords: string ) {
        if (!this.keywords) {
            this.keywords = {
                name: 'keywords',
                content: ''
            };
        }
        this.keywords.content = keywords;
        if (this.meta.getTag('name="keywords"')) {
            this.meta.updateTag(this.keywords);
        } else {
            this.meta.addTag(this.keywords);
        }
    }

    setDescription( description: string ) {
        if (!this.description) {
            this.description = {
                name: 'description',
                content: ''
            };
        }
        this.description.content = description;
        this.meta.updateTag(this.description);
        if (this.meta.getTag('name="description"')) {
            this.meta.updateTag(this.description);
        } else {
            this.meta.addTag(this.description);
        }
        this.setMetaPropertyTag('og:description', description);
        this.setMetaPropertyTag('twitter:description', description);
    }

    setMetaPropertyTag( tag: string, content: string) {
        const metaDef: MetaDefinition = { property: tag, content };
        if (this.meta.getTag('property="' + tag + '"')) {
            this.meta.updateTag(metaDef);
        } else {
            this.meta.addTag(metaDef);

            // Have to move the meta tags up to the top of the head so crawlers see them first
            const metaTags = Array.from(this.document.getElementsByTagName('meta'));
            const headElement = this.document.getElementsByTagName('head')[0];
            let topLevelHeadChild = Array.from(headElement.children)
                .filter(childNode => !(childNode instanceof HTMLMetaElement))[0];

            while (metaTags.length > 0) {
                const metaTag = metaTags.pop();
                headElement.insertBefore(metaTag, topLevelHeadChild);
                topLevelHeadChild = metaTag;
            }
        }
    }

    appendLinkTag(rel: string, type: string, href: string, title: string) {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', rel);
      link.setAttribute('type', type);
      link.setAttribute('href', href);
      link.setAttribute('title', title);
      const headElement = this.document.getElementsByTagName('head')[0];
      headElement.appendChild(link);
    }

    removeMetaPropertyTag(tag: string) {
        this.meta.removeTag('property="' + tag + '"');
    }

    private setPostTags(post: Post) {
        debug('setPostTags', post);
        this.setType(post);

        if (post.tags.indexOf('type-video') !== -1) {
            // video post
            const url =  environment.postdBaseUrl + '/embed?post_id=' + post.id;
            this.setMetaPropertyTag('og:video', url);
            this.setMetaPropertyTag('og:video:type', 'application/html');
        }


        if (post.firstLeadWords) {
            const description = this.domSanitizer.sanitize(SecurityContext.HTML, post.firstLeadWords);
            if (description.length > 0) {
                // strip html tags, unneeded
                this.setDescription( description.replace(/\n/g, '').replace(/<.*?>/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;') );
            }
        }

        if (post.leadContent) {
            const description = this.domSanitizer.sanitize(SecurityContext.HTML, post.leadContent);
            if (description.length > 0) {
                // strip html tags, unneeded
                this.setDescription( description.replace(/\n/g, '').replace(/<.*?>/g, '').replace(/</g, '&lt;').replace(/>/g, '&gt;') );
            }
        }
        const imageUrl = post.thumbnailImage ? post.thumbnailImage.replace(/\?u=\d+/, '') : this.postdLogo;
        if (post.thumbnailMeta) {
            this.setMetaPropertyTag('og:image:width', post.thumbnailMeta.width + '');
            this.setMetaPropertyTag('og:image:height', post.thumbnailMeta.height + '');
            this.setMetaPropertyTag('twitter:image:width', post.thumbnailMeta.width + '');
            this.setMetaPropertyTag('twitter:image:height', post.thumbnailMeta.height + '');
        } else {
            this.removeMetaPropertyTag('og:image:width');
            this.removeMetaPropertyTag('og:image:height');
            this.removeMetaPropertyTag('twitter:image:width');
            this.removeMetaPropertyTag('twitter:image:height');
        }
        this.setMetaPropertyTag('og:image', imageUrl);
        this.setMetaPropertyTag('twitter:image', imageUrl);
    }

    private setType(post: Post) {
        // switch (post.premiumMediaType) {
        //     case 'audio':
        //         this.setMetaPropertyTag('og:type', 'postd:audio');
        //         break;
        //     case 'video':
        //         this.setMetaPropertyTag('og:type', 'postd:video');
        //         break;
        //     default:
        //     this.setMetaPropertyTag('og:type', 'postd:article');
        // }
    }

    private setChannelTags(channel: Channel) {
        this.setMetaPropertyTag('og:type', 'website');
        if (channel.description && channel.description.length > 0) {
            this.setDescription(channel.description);
        }
        const bannerImgUrl = channel.bannerImageUrl ? channel.bannerImageUrl : this.postdLogo;
        const iconImgUrl = channel.iconImageUrl ? channel.iconImageUrl : this.postdLogoSquare;
        // Most social sharing images expect the square format
        this.setMetaPropertyTag('og:image', iconImgUrl);
        this.setMetaPropertyTag('og:image:width', '400');
        this.setMetaPropertyTag('og:image:height', '400');
        this.setMetaPropertyTag('twitter:image', iconImgUrl);
    }

    private setPlaylistTags(firstPost: Post, playlist: Playlist) {
        const imageUrl = firstPost.thumbnailImage ? firstPost.thumbnailImage.replace(/\?u=\d+/, '') : this.postdLogo;
        if (firstPost.thumbnailMeta) {
            this.setMetaPropertyTag('og:image:width', firstPost.thumbnailMeta.width + '');
            this.setMetaPropertyTag('og:image:height', firstPost.thumbnailMeta.height + '');
            this.setMetaPropertyTag('twitter:image:width', firstPost.thumbnailMeta.width + '');
            this.setMetaPropertyTag('twitter:image:height', firstPost.thumbnailMeta.height + '');
        } else {
            this.removeMetaPropertyTag('og:image:width');
            this.removeMetaPropertyTag('og:image:height');
            this.removeMetaPropertyTag('twitter:image:width');
            this.removeMetaPropertyTag('twitter:image:height');
        }
        this.setMetaPropertyTag('og:image', imageUrl);
        this.setMetaPropertyTag('twitter:image', imageUrl);

        this.setMetaPropertyTag('og:type', 'website');
        if (playlist.description && playlist.description.length > 0) {
            this.setDescription(playlist.description);
        }
    }

    private clearSpecialTags() {
        this.meta.removeTag('property="og:type"');
        this.meta.removeTag('property="og:image"');
        this.meta.removeTag('property="twitter:image"');
    }

    private getId(url: string, type: string): string {
        // split on forward slash or ? or &
        const urlParts = url.split(/[\/?&=]/);
        const postIndx = urlParts.indexOf(type);
        let id = '';
        if (postIndx >= 0) {
            if (postIndx < urlParts.length - 1) {
                id = urlParts[postIndx + 1];
            }
        } else {
          for (const element of urlParts) {
            if (type === 'post' && this.idCheck(element)) {
              debug('getId found element id', element);
              return element;
            }
          }
        }
        debug('getId id', id);
        return id;
    }

    private idCheck(postId: string): boolean {
        let pass = false;
        if (postId.length > 10 && postId.indexOf('_') > 0) {
            pass = true;
        }
        return pass;
    }

}

/* Tag checking sites:
    https://developers.facebook.com/tools/debug/

*/

/*
og:title content=”Your eye-catching title here” />
og:url content=”http://www.yourdomain.com” />
og:type content=”website” />
og:description content=”Your entertaining and descriptive copy here, if your meta description is good, use it.” />
og:image content=”http://www.yourdomain.com/image-name.jpg” />
og:locale – defines the language, American English is the default
og:site_name – if the page (object) you are sharing is part of a larger network
og:audio or og:video – to add additional audio or video files to your object
*/
