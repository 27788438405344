import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'clascatcap'
})
export class ClassificationCategoryCapitalizePipe implements PipeTransform {
  private re;
  constructor() {
    this.re = /\b[a-z]/g;
  }
  transform(item: string) {
    const lc = item.toLowerCase().replace(' us', ' US').replace('tv', 'TV');
    const updated = lc.replace(this.re, function(x) {
return x.toUpperCase();
});
    return updated;
  }
}
