import { Injectable } from '@angular/core';
import * as Debug from 'debug';
import * as lscache from 'lscache';

// types
import { Channel } from '@shared/types/channel';
import { Post } from '@shared/types/post';

import { setPurchaseToken } from '@shared/interceptors/api.interceptor';

import { environment } from '@env/environment';

const debug = Debug('shared:cache_service');

@Injectable()
export class CacheService {
  cacheKeys = {
    channel: 'channel:',
    userChannels: 'userChannels:',
    channelsFollowed: 'profile:following',
    purchaseToken: 'purchased:',
    post: 'post:',
    postLead: 'postLead:',
    blacklist: 'blacklist',

    userCachesList: 'userCachesList'
  };

  // default to 5 mintes, if this is too high user might wonder why update has not been shown.
  private cacheMinutes = 5;

  constructor() {
  }

  clearUserCache() {
    const userKeys = Object.keys(lscache.get(this.cacheKeys.userCachesList) || {});
    for (const key of userKeys) {
      lscache.remove(key);
    }
    lscache.set(this.cacheKeys.userCachesList, {});
  }

  clearCachedPost(postId: string) {
    lscache.remove(postId);
  }

  getChannelInfo(channelId: string): Channel {
    return environment['test'] ? null : lscache.get(this.cacheKeys.channel + channelId);
  }
  setChannelInfo(channelId: string, channelData: Channel) {
    if (environment['test']) {
      return;
    }
    lscache.set(this.cacheKeys.channel + channelId, this.cloneObj(channelData), this.cacheMinutes);
  }

  getPostPurchaseToken(postId: string): string {
    return lscache.get(this.cacheKeys.purchaseToken + postId);
  }
  setPostPurchaseToken(postId: string, token: string) {
    const key  = this.cacheKeys.purchaseToken + postId;
    this.markUserCache(key);

    setPurchaseToken(token, postId, null);

    // cache post token for 10 hours
    lscache.set(key, token, 600);
  }

  getPostInfo(postId): Post {
    if (environment.test) {
      return null;
    }
    const post = lscache.get(this.cacheKeys.post + postId);
    debug('getPostInfo: ' + postId, post);
    return post;
  }
  setPostInfo(postId, postData: Post) {
    if (environment.test) {
      return;
    }
    debug('setPostInfo: ' + postId, postData);
    lscache.set(this.cacheKeys.post + postId, this.cloneObj(postData), this.cacheMinutes);
  }

  getPostLead(postId) {
    return lscache.get(this.cacheKeys.postLead + postId);
  }
  setPostLead(postId, postData) {
    lscache.set(this.cacheKeys.postLead + postId, this.cloneObj(postData), this.cacheMinutes);
  }

  getBlacklist(): { [key: string]: boolean } {
    return lscache.get(this.cacheKeys.blacklist) || {};
  }
  blacklistPost(postId, err: any): void {
    const currCache: { [key: string]: boolean } = lscache.get(this.cacheKeys.blacklist) || {};
    currCache[postId] = err;
    this.markUserCache(this.cacheKeys.blacklist);
    lscache.set(this.cacheKeys.blacklist, currCache, this.cacheMinutes);
  }

  markUserCache(key) {
    const userKeys = lscache.get(this.cacheKeys.userCachesList) || {};
    userKeys[key] = true;
    lscache.set(this.cacheKeys.userCachesList, userKeys, 99999999);
  }

  private cloneObj(object) {
    return JSON.parse(JSON.stringify(object));
  }

}
