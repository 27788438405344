import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@env/environment';
import * as Debug from 'debug';
import { assign, filter, find, findIndex, last, map, max, sortBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { mockDB } from '../../assets/mock/mock.db';
import { Channel } from '../types/channel';
import { Post } from '../types/post';
import { User } from '../types/user';
const debug = Debug('interceptor:ApiTestInterceptor');

@Injectable()
export class ApiTestInterceptor implements HttpInterceptor {
  BASE_API: string;
  stream: any;
  fileName: string;
  mockData: any = {};
  testing: boolean;
  checkingAfterUpload: boolean;
  currentUser: User = null;
  newChannelName = 'New Channel';
  maxPostId = 0;
  infoCount = 0;

  constructor(@Optional() @Inject('ORIGIN_URL') protected originUrl: string) {
    // this.BASE_API = environment.apiServer;
    if (!this.BASE_API) {
      this.BASE_API = originUrl;
    }
    // generate localstorage database for testing from mock-db
    this.testing = false;

    if (environment.test) {
      // Generate the local mockData
      debug('we have the test environment');
      debug('environment.apiServer', environment.apiServer);
      debug('origin url', originUrl);
      debug('constructor originUrl', this.originUrl);
      this.testing = true;
      const dbObjects = Object.keys(mockDB).filter((k, i, l) => !(k === 'excluded' || mockDB.excluded.indexOf(k) >= 0));
      dbObjects.forEach((key) => {
        if (key !== 'posts') {
          this.mockData[key] = mockDB[key];
        }
      });
      this.getPostAndChannelInfo();
      dbObjects.forEach((key) => {
        if (key !== 'config') {
          debug(this.mockData[key].length.toFixed(0) + ' mock ' + key + ' entries.');
        }
      });
      debug('Mock DB config:' + JSON.stringify(this.mockData.config));
      debug('finished constructor');
    }
  }

  intercept(origReq: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let result: Observable<HttpEvent<any>> = null;
    const serverReq = !this.originUrl
      ? origReq
      : origReq.clone({
          url: this.apiUrl(origReq.url),
        });
    if (this.testing) {
      const req = !this.originUrl
        ? origReq
        : origReq.clone({
            url: origReq.url.replace(environment.apiServer, '').replace(this.BASE_API, ''),
          });
      if (req.url.startsWith('/api/post')) {
        if (req.method === 'GET') {
          const postId = this.getId(req);
          const mockPost = find(this.mockData.posts, { id: postId });
          if (mockPost) {
            if (req.url.endsWith('/info')) {
              // add code to go to mock server for media update
              if (this.checkingAfterUpload) {
                const postInfo = this.getPostInfoAfterUpload(mockPost);
                result = of(new HttpResponse({ status: 200, body: postInfo }));
              } else {
                result = of(new HttpResponse({ status: 200, body: mockPost }));
              }
            } else if (req.url.indexOf('media/upload_url') >= 0) {
              const urlParts = req.url.split('?');
              if (urlParts.length === 2) {
                const paramsArray = urlParts[1].split('&');
                const fileName = this.getParam(paramsArray, 'filename');
                const fileType = this.getParam(paramsArray, 'ctype');
                const section = this.getMediaSection(req);
                const upload_url =
                  'http://localhost:7700/api/mockupload/' + postId + '/' + section + '/' + fileName;
                debug('upload url is', upload_url);
                this.checkingAfterUpload = true;
                this.infoCount = 0;
                result = of(new HttpResponse({ status: 200, body: { upload_url } }));
              }
            } else if (req.url.endsWith('lead/content')) {
              result = of(new HttpResponse({ status: 200, body: mockPost['leadContent'] }));
            } else if (req.url.endsWith('premium/content')) {
              result = of(new HttpResponse({ status: 200, body: mockPost['premiumContent'] }));
            } else if (req.url.endsWith('comments')) {
              result = of(new HttpResponse({ status: 200, body: [] }));
            } else if (req.url.endsWith('ratings')) {
              result = of(new HttpResponse({ status: 200, body: [] }));
            } else if (req.url.endsWith('rating')) {
              result = of(new HttpResponse({ status: 200, body: [] }));
            }
          } else {
            debug('post not found in mock DB', postId);
          }
        } else if (req.method === 'POST') {
          // debug('test intercept orignReq <---- ', origReq);
          // debug('serverReq', serverReq);
          // debug('resulting req', req);
          const postId = this.getId(req);
          const postIndex = findIndex(this.mockData.posts, { id: postId });
          if (req.url.endsWith('name')) {
            const newName = req.body.name;
            if (postIndex >= 0) {
              this.mockData.posts[postIndex].name = newName;
              result = of(new HttpResponse({ status: 200, body: { result: 'post name changed' } }));
            }
          } else if (req.url.endsWith('content')) {
            const newContent = req.body.content;
            let section = '';
            if (postIndex >= 0 && req.url.endsWith('premium/content')) {
              section = 'premium';
              this.mockData.posts[postIndex].premiumContent = newContent;
            } else if (postIndex >= 0 && req.url.endsWith('lead/content')) {
              this.mockData.posts[postIndex].leadContent = newContent;
              section = 'lead';
            }
            result = of(
              new HttpResponse({ status: 200, body: 'post ' + section + ' content changed' })
            );
          } else if (req.url.indexOf('/min-age/')) {
            const urlParts = req.url.split('/');
            const age = parseInt(urlParts[urlParts.length - 1], 10);
            this.mockData.posts[postIndex].age = age;
            result = of(new HttpResponse({ status: 200, body: null }));
          } else if (req.url.indexOf('/state/') >= 0) {
            const state = req.url.replace(/.*?\/state\/([\w]+)\/?/, '$1');
            this.mockData.posts[postIndex].state = state;
            result = of(new HttpResponse({ status: 200, body: this.mockData.posts[postIndex] }));
          }
        } else if (req.method === 'PUT') {
          const urlParts = req.url.split('/');
          const postId = this.getId(req);
          const postIndex = findIndex(this.mockData.posts, { id: postId });
          if (postIndex >= 0 && urlParts.length > 5 && urlParts[4] === 'price') {
            const newPrice = urlParts[5];
            this.mockData.posts[postIndex].price = parseInt(newPrice, 10);
            result = of(new HttpResponse({ status: 200, body: { result: 'price changed' } }));
          } else if (postIndex >= 0 && req.url.endsWith('/tags')) {
            this.mockData.posts[postIndex].tags = req.body.tags.split(' ');
            result = of(new HttpResponse({ status: 200, body: { result: 'tags updated' } }));
          } else if (postIndex >= 0 && req.url.endsWith('/countries')) {
            this.mockData.posts[postIndex].countries = req.body.countries;
            result = of(new HttpResponse({ status: 200, body: { result: 'countries updated' } }));
          }
        }
      }

      if (req.url.startsWith('/api/channel')) {
        if (req.url.endsWith('/profile')) {
          const chanId = this.getId(req);
          // debug('channel id', chanId);
          const mockChannelIndx = findIndex(this.mockData.channels, { id: chanId });
          if (req.method === 'GET') {
            if (mockChannelIndx >= 0) {
              // debug('returning channel from mock DB', mockChannelIndx);
              result = of(
                new HttpResponse({
                  status: 200,
                  body: this.mockData.channels[mockChannelIndx],
                })
              );
            } else {
              debug('channel not found in mock DB', chanId, req, result);
            }
          } else if (req.method === 'POST') {
            if (mockChannelIndx >= 0) {
              // debug('returning channel from mock DB', mockChannelt);
              this.mockData.channels[mockChannelIndx].name = req.body.name;
              result = of(
                new HttpResponse({
                  status: 200,
                  body: this.mockData.channels[mockChannelIndx],
                })
              );
            } else {
              debug('channel not found in mock DB', chanId, req, result);
            }
          } else {
            debug('test intercept <---- ', req);
          }
        } else if (
          req.url.endsWith('/posts/list') ||
          (req.url.indexOf('/posts/list?') >= 0 && req.method === 'GET')
        ) {
          const chanId = this.getId(req);
          // debug('interceptor posts in mockdb', this.mockData.posts);
          const channelPosts = filter(this.mockData.posts, { channelId: chanId });
          // debug('interceptor channelPosts', channelPosts);
          if (channelPosts) {
            result = of(
              new HttpResponse({
                status: 200,
                body: {
                  total: channelPosts.length,
                  posts: channelPosts,
                },
              })
            );
          }
        } else if (req.url.indexOf('/posts/list') > 0 && req.method === 'GET') {
          const chanId = this.getId(req);
          const urlParts = req.url.split('?');
          if (urlParts.length === 2) {
            const paramsArray = urlParts[1].split('&');
            const size = parseInt(this.getParam(paramsArray, 'size'), 10);
            const fromOffset = parseInt(this.getParam(paramsArray, 'fromOffset'), 10);
            const channelPosts = filter(this.mockData.posts, { channelId: chanId });
            const requestedPosts = channelPosts.slice(fromOffset, fromOffset + size);
            result = of(
              new HttpResponse({
                status: 200,
                body: { total: channelPosts.length, posts: requestedPosts },
              })
            );
          }
        } else if (
          (req.url.endsWith('/post/new') || req.url.indexOf('post/new?') >= 0) &&
          req.method === 'GET'
        ) {
          const chanId = this.getId(req);
          const newPost = this.makeNewPost(chanId);
          if (newPost) {
            result = of(new HttpResponse({ status: 200, body: newPost }));
          }
        } else if (req.url.endsWith('/playlists') && req.method === 'GET') {
          const chanId = this.getId(req);
          const playlists = filter(this.mockData.playlists, { channelId: chanId });
          result = of(new HttpResponse({ status: 200, body: playlists }));
        } else if (req.url.endsWith('/delete')) {
          const postId = req.url.replace(/.*?\/([\d_-]+)\/delete/, '$1');
          this.mockData.posts = this.mockData.posts.filter((post) => post.postId !== postId);
          result = of(new HttpResponse({ status: 200, body: 'ok' }));
        } else {
          debug('test intercept <---- ', req);
        }
      }

      if (req.url.startsWith('/api/user')) {
        debug(' api user call: ', req.url);
        if (req.url.endsWith('profile') && req.method === 'GET') {
          this.currentUser = this.mockData.users[0];
          result = of(new HttpResponse({ status: 200, body: this.currentUser }));
        }
        if (this.currentUser && req.method === 'GET') {
          if (req.url.endsWith('channel/list')) {
            // get user ID and then get all of the channels matching the id from the mock channels
            const userChannels = filter(this.mockData.channels, { userId: this.currentUser.id });
            debug('channel/list channels', userChannels);
            if (userChannels) {
              result = of(new HttpResponse({ status: 200, body: userChannels }));
            } else {
              result = of(new HttpResponse({ status: 200, body: [] }));
            }
          }
          if (req.url.endsWith('channels/following')) {
            // get user ID and then get all of the channels matching the id from the mock channels
            debug('user channels following', this.currentUser, this.mockData.channel_followers);
            const userChannels = filter(this.mockData.channel_followers, {
              userId: this.currentUser.id,
            });
            if (userChannels) {
              debug('user following channels found', userChannels);
              result = of(new HttpResponse({ status: 200, body: userChannels }));
            } else {
              debug('no user channels found returning empty array');
              result = of(new HttpResponse({ status: 200, body: [] }));
            }
          }
          if (req.url.indexOf('channel/new') >= 0) {
            debug('api make new channel for user');
            const name = req.url.replace(/^.*?new\?name=(.*?)$/, '$1');
            const newChannel = this.makeNewChannel();
            newChannel.name = name;
            this.mockData.channels.push(newChannel);
            result = of(new HttpResponse({ status: 200, body: newChannel }));
          }
          if (req.url.endsWith('notifications')) {
            result = of(new HttpResponse({ status: 200, body: this.mockData.notifications }));
          }
          if (req.url.endsWith('posts/ratings')) {
            result = of(new HttpResponse({ status: 200, body: [] }));
          }
          if (req.url.endsWith('posts/comments')) {
            result = of(new HttpResponse({ status: 200, body: [] }));
          }
          if (!result) {
            debug('test intercept <---- ', req);
          }
        }
      }

      if (req.url.startsWith('/api/search/posts') && req.method === 'GET') {
        // debug('got /api/search/posts', req);
        let orderedPosts = this.mockData.posts;
        if (req.url.indexOf('query') < 0) {
          if (req.url.endsWith('newest')) {
            const newest = sortBy(orderedPosts, 'updated')
              .reverse()
              .slice(0, this.mockData.config.newPostLength);
            debug('newests posts', newest);
            result = of(new HttpResponse({ status: 200, body: newest }));
            // debug('newest posts', newest);
          } else if (req.url.endsWith('featured')) {
            const featured = filter(orderedPosts, (p) => p.featured > 0);
            result = of(new HttpResponse({ status: 200, body: featured }));
            // debug('featured posts', featured);
          } else if (req.url.indexOf('featured/type-') > 0) {
            const urlParts = req.url.split('/');
            const featured = filter(orderedPosts, (p) => p.featured > 0 && p.tags.indexOf(urlParts[urlParts.length - 1]) >= 0);
            result = of(new HttpResponse({ status: 200, body: featured }));
          } else {
            debug('test intercept <---- ', req);
          }
        } else if (req.url.indexOf('simple/query') >= 0) {
          const urlParts = req.url.split('?');
          if (urlParts.length === 2) {
            const parms = this.getAllParams(urlParts[1]);
            const posts = filter(this.mockData.posts, (p) => {
              let passes = true;
              if (parms.tag !== '') {
                if (p.tags.join('').indexOf(parms.tag) < 0) {
                  passes = false;
                }
              }
              if (parms.query !== '') {
                // indexedName, firstPremWords, firstLeadWords
                const searchStr = p.indexedName + p.firstLeadWords + p.firstPremWords;
                if (searchStr.indexOf(parms.query) < 0) {
                  passes = false;
                }
              }
              return passes;
            });
            orderedPosts = this.applySort(posts, parms.sortField);
            const requestedPosts = posts.slice(parms.fromOffset, parms.fromOffset + parms.size);
            result = of(
              new HttpResponse({
                status: 200,
                body: { total: posts.length, posts: requestedPosts },
              })
            );
          }
        } else {
          if (req.url.indexOf('dateDesc') >= 0) {
            orderedPosts = sortBy(this.mockData.posts, ['updated']).reverse();
          } else if (req.url.indexOf('ratingDesc') >= 0) {
            orderedPosts = sortBy(this.mockData.posts, ['rating']).reverse();
          } else if (req.url.indexOf('purchaseCountDesc') >= 0) {
            orderedPosts = sortBy(this.mockData.posts, ['purchaseCount']).reverse();
          }
          if (req.url.indexOf('tag=') >= 0) {
            const filterTag = req.url.replace(/.*?tag=([\w\-]+)(\&.*)?/, '$1');
            orderedPosts = orderedPosts.filter((post) => post.tags.indexOf(filterTag) >= 0);
          }
          if (req.url.indexOf('size=') >= 0) {
            const size = parseInt(req.url.replace(/.*?size=([\w\-]+)(\&.*)?/, '$1'), 10);
            if (!isNaN(size)) {
              orderedPosts = orderedPosts.slice(0, size);
            }
          }
          result = of(new HttpResponse({ status: 200, body: { posts: orderedPosts } }));
        }
      }

      if (req.url === '/api/geographic/countries/dict' && req.method === 'GET') {
        result = of(new HttpResponse({ status: 200, body: this.mockData.countries_dict }));
      }

      if (
        req.url.startsWith('/api/geographic/country') &&
        req.url.endsWith('states') &&
        req.method === 'GET'
      ) {
        const urlParts = req.url.split('/');
        const country = urlParts[4];
        const statesData = this.mockData.country_states[country]
          ? this.mockData.country_states[country]
          : [];
        result = of(new HttpResponse({ status: 200, body: statesData }));
      }

      if (req.url.startsWith('/public-images') && req.method === 'GET') {
        // serve up local image
        const urlParts = req.url.split('/');
        debug('public images', req, urlParts);
        result = of(
          new HttpResponse({ status: 200, body: 'assets/mock/images/' + last(urlParts) })
        );
      }

      if (req.url.startsWith('/perplay-media') && req.method === 'GET') {
        // serve up local image
        debug('got perplay-media request', req);
      }
    } // end if testing

    // pass through to regular interceptor
    if (!result) {
      if (this.testing) {
        // if (serverReq.url.indexOf('7700')<0) {
        debug('non intercepted req', serverReq);
        next.handle(serverReq).subscribe((resp) => {
          if (resp instanceof HttpResponse) {
            debug('test interceptor response', resp);
            debug('url: ' + resp.url, { json: JSON.stringify(resp.body, null, 2) });
            // update mockData
            // write localhost mockData

            // debug(apiCall[1], JSON.stringify(resp.body,null,2));

            // add to local data base and update local storage
          }
        });
        // }
      }
      result = next.handle(serverReq);
    }
    return result;
  }

  private getId(r: HttpRequest<any>): string {
    let res = '';
    const urlParts = r.url.split('/');
    if (urlParts.length >= 3) {
      res = urlParts[3];
    }
    return res;
  }

  private getMediaSection(r: HttpRequest<any>): string {
    let res = '';
    const urlParts = r.url.split('/');
    if (urlParts.length >= 4) {
      res = urlParts[4];
    }
    return res;
  }

  private apiUrl(url: string): string {
    // if we should prefix our API server
    if (url.startsWith('/api')) {
      return this.BASE_API + url;
    } else if (url.startsWith('/')) {
      return this.originUrl + url;
    }

    return url;
  }

  private applySort(posts: Post[], sort: string): Post[] {
    const sortField = sort.replace('Desc', '').replace('Asc', '');
    let orderedPosts: Post[] = posts;
    if (sortField === 'rating' || sortField === 'date' || sortField === 'purchaseCount') {
      orderedPosts = sortBy(posts, [sortField]);
    }
    if (sort.endsWith('Desc')) {
      orderedPosts.reverse();
    }
    return orderedPosts;
  }

  private getAllParams(reqParms) {
    const paramsArray = reqParms.split('&');
    const parmSize = this.getParam(paramsArray, 'size');
    const size = parmSize !== '' ? parseInt(parmSize, 10) : 50;
    const parmOffset = this.getParam(paramsArray, 'fromOffset');
    const fromOffset = parmOffset !== '' ? parseInt(parmOffset, 10) : 0;
    const tag = this.getParam(paramsArray, 'tag');
    const query = this.getParam(paramsArray, 'q');
    const sortField = this.getParam(paramsArray, 'sort');
    return {
      size,
      fromOffset,
      tag,
      query,
      sortField,
    };
  }

  private getPostAndChannelInfo() {
    if (!this.mockData['posts']) {
      this.mockData['posts'] = [];
    }
    mockDB.posts.forEach((post) => {
      const newPost: Post = new Post(post);
      const postId: string = newPost.id.replace(newPost.channelId, '').replace('_', '');
      const postNum: number = parseInt(postId, 10);
      if (postNum > this.maxPostId) {
        this.maxPostId = postNum;
      }
      const postChannel = find(mockDB.channels, { id: post.channelId });
      if (postChannel) {
        const addedProps = {
          admins: [],
          bannerImageUrl: '',
          bannerImage: '',
          iconImageUrl: '',
          iconImage: '',
          followers: 0,
        };
        newPost.channelInfo = assign(new Channel(), addedProps, postChannel);
      } else {
        debug('post has channel not in mock db', post);
      }
      if (post['featured']) {
        newPost['featured'] = post['featured'];
      }
      if (newPost.thumbnailImage && newPost.thumbnailImage.startsWith('perplay-media')) {
        newPost.thumbnailImage = 'https://s3.amazonaws.com/' + newPost.thumbnailImage;
      }
      this.mockData.posts.push(newPost);
    });
    debug('max post Id', this.maxPostId);
  }

  private makeNewChannel() {
    let newChannelName = '';
    const userChannels = filter(this.mockData.channels, { userId: this.currentUser.id });
    debug('userChannels', userChannels);
    const maxChannelId = max(
      map(userChannels, (c) => c['id'].replace(this.currentUser.id + '_', ''))
    );
    const newChannels = filter(userChannels, (c) => c['name'].indexOf(this.newChannelName) >= 0);
    if (newChannels.length > 0) {
      const newChannelIndexes = map(newChannels, (c) => parseInt(c['name'].replace(this.newChannelName + ' ', ''), 10));
      newChannelName = this.newChannelName + ' ' + (max(newChannelIndexes) + 1);
    } else {
      newChannelName = this.newChannelName + ' 1';
    }
    const now = Date.now();
    const nextChannelIndex = parseInt(maxChannelId, 10) + 1;
    const newChannel = {
      name: newChannelName,
      description: '',
      followers: 0,
      numPosts: 0,
      numSubs: 0,
      id: this.currentUser.id + '_' + nextChannelIndex,
      userId: this.currentUser.id,
      updated: now,
      created: now,
      indexedName: newChannelName.toLowerCase(),
      featuredPosts: [],
      ratingAvg: 0,
    };
    this.mockData.channels.push(newChannel);
    debug('updated channels', this.mockData.channels);
    return newChannel;
  }

  private makeNewPost(chanId: string): Post {
    const postId = (this.maxPostId + 1).toString();
    const now = new Date();
    const name = 'New Post ' + now.toUTCString();
    const newPost = {id: chanId + '_' + postId,
      channelId: chanId, userId: this.currentUser.id,
      name,
      tags: ['kind-text'], indexedName: name.toLowerCase(),
      state: 'HIDDEN', featured: 0, leadMedia: [], leadWrdCnt: 0, premiumMedia: [],
      premWrdCnt: 0, commentsCount: 0, viewCount: 0, purchaseCount: 0, likesCount: 0,
      price: 1, currency: 'PROD', rating1star: 0, rating2star: 0, rating3star: 0,
      rating4star: 0, rating5star: 0, abuseCount: 0, minAge: 0, updated: now,
      created: now};
    const newPostCreated = new Post(newPost);
    this.mockData.posts.push(newPostCreated);
    debug('newPostCreated', newPostCreated);
    return newPostCreated;
  }

  private makeErrResponse(url: string, status: number): HttpErrorResponse {
    const resp = new HttpErrorResponse({
      error: mockDB.error.errors[status],
      status,
      statusText: mockDB.error.statusText[status],
      url,
    });
    return resp;
  }

  private getParam(paramsArray: string[], param: string): string {
    let result = '';
    paramsArray.forEach((p) => {
      if (p.startsWith(param + '=')) {
        result = p.split('=')[1];
      }
    });
    return result;
  }

  private getPostInfoAfterUpload(thisPost: any) {
    const postId = thisPost.id;

    if (thisPost) {
      const premMedia = mockDB.premium_media;
      // debug('premMedia', premMedia);
      const premMediaJson = JSON.stringify(premMedia);
      // debug('premMediaJson',premMediaJson);
      const embeddedId = this.getPostId(premMedia.download);
      if (this.infoCount > 2) {
        thisPost.premiumMedia[thisPost.premiumMedia.length - 1].state = 'COMPLETED';
      } else if (this.infoCount > 1) {
        thisPost['premiumMedia'] = [];
        const newMedia = JSON.parse(premMediaJson.replace(embeddedId, postId));
        newMedia.state = 'PROGRESSING';
        const keyParts = newMedia.key.split('/');
        keyParts[0] = thisPost.id;
        const newKey = keyParts.join('/');
        newMedia.key = newKey;
        thisPost.premiumMedia.push(newMedia);
        thisPost.premiumMediaType = 'video';
        thisPost.tags = ['kind-video'];
        this.infoCount++;
      } else {
        this.infoCount++;
      }
      debug('get', postId, thisPost);
      return thisPost;
    }
    // else {
    //   var postIdRequested = getPostId(req.url);
    //   var postRequested = findPostInDB(postIdRequested);
    //   if (postRequested) {
    //     res.send(postRequested);
    //   } else {
    //     res.end();
    //   }
    // }
  }

  getPostId = (url) => url.split('/')[3];
}
