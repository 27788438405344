import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../../common/common.module';

import { StarRatingComponent } from '../../../../frontend/core/forms/star-rating/star-rating.component';

@NgModule({
  declarations: [
    StarRatingComponent
  ],
  imports: [ PostdCommonModule ],
  exports: [ StarRatingComponent ]
})

export class StarRatingModule {}
