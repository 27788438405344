import * as Debug from 'debug';
const debug = Debug('shared:PlaylistService');

import { Injectable, Inject, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Post } from '../types/post';

import * as _ from 'lodash';

/**
 * @param postId the string ID of the post
 * @param preview true if the video in the queue is the free preview video.
 * @param post optional. The post object
 * @param nextPostIds optional. The next posts to offer to play after this one.
 */
export interface QueueItem {
  postId: string;
  post?: Post;
  nextPostIds?: string[];
  showNextVideo: boolean;
  preview: boolean;
}

@Injectable()
export class PlayQueueService {
  private activeIndex: number;
  private lastActiveIndex: number;
  private queueSource = new BehaviorSubject<QueueItem[]>(undefined);
  private activePostSource = new BehaviorSubject<{ queueItem: QueueItem; playing: boolean }>(undefined);
  private videoPlayerSnapSource = new BehaviorSubject<{
    snapElementRef: ElementRef<any>;
    postId: string;
    fillWindow: boolean;
  }>(undefined);
  currQueue$ = this.queueSource.asObservable();
  currPlaying$ = this.activePostSource.asObservable();
  videoPlayerSnap$ = this.videoPlayerSnapSource.asObservable();

  queue: QueueItem[];

  get activePost() {
    if (this.activeIndex === undefined) {
      return undefined;
    } else {
      return this.queue[this.activeIndex];
    }
  }
  get nextPost() {
    if (this.activeIndex === undefined) {
      return undefined;
    } else {
      return this.queue[this.activeIndex];
    }
  }

  constructor(@Inject('sessionStorage') private sessionStorage: any) {
    debug('PlayQueueService constructor');
    this.queue = this.getStoredValue();
    if (this.queue) {
      this.queueSource.next(this.queue);
      this.lastActiveIndex = this.getBookmark();
      if (this.lastActiveIndex >= 0) {
        this.activePostSource.next({ queueItem: this.queue[this.lastActiveIndex], playing: false });
      }
    } else {
      this.lastActiveIndex = 0;
    }
  }

  playNext(post?: number): QueueItem;
  playNext(post: Post | string, preview: boolean): QueueItem;
  playNext(post?: Post | number | string, preview?: boolean): QueueItem {
    if (post instanceof Post) {
      this.activeIndex = this.queue.findIndex((queueItem) => queueItem.postId === post.id && queueItem.preview === !!preview);
    } else if (typeof post === 'string') {
      this.activeIndex = this.queue.findIndex((queueItem) => queueItem.postId === post && queueItem.preview === !!preview);
    } else if (typeof post === 'number') {
      this.activeIndex = post;
    } else {
      // If this is called with no params, play the next video
      // If we aren't currently playing anything, start from the beginning
      if (this.activeIndex === undefined) {
        this.activeIndex = this.lastActiveIndex || 0;
      } else {
        this.activeIndex++;
      }
      // If there are no more videos in the queue, stop playing
      if (this.queue[this.activeIndex] === undefined) {
        this.stop();
        this.lastActiveIndex = 0;
        return undefined;
      }
    }
    if (this.activePost) {
      this.activePostSource.next({ queueItem: this.activePost, playing: true});
    } else {
      this.activePostSource.next({ queueItem: this.queue[this.lastActiveIndex], playing: false });
    }
    this.bookmark(this.activeIndex || 0);
    return this.queue[this.activeIndex];
  }

  clear() {
    this.queue = [];
    this.queueSource.next(this.queue);
  }

  stop() {
    this.lastActiveIndex = this.activeIndex;
    this.activeIndex = undefined;
    this.activePostSource.next({ queueItem: this.queue[this.lastActiveIndex], playing: false });
  }

  endPlayingCurrentVideo() {
    if (typeof this.activeIndex !== 'undefined') {
      this.activePostSource.next({ queueItem: this.queue[this.activeIndex], playing: false });
    }
  }

  prependToPlayqueue(post: Post, mode: 'premium' | 'lead' = 'premium', options?: {
    nextPostIds?: string[];
    showNextVideo?: boolean;
  }) {
    debug('Add post to beginning queue');
    this.queue = this.getStoredValue();
    if (!this.queue) {
      this.queue = [];
    }
    const { nextPostIds, showNextVideo } = { showNextVideo: true, ...options };
    const queueItem = _.find(this.queue, { postId: post.id, preview: mode === 'lead' });
    if (!queueItem) {
      if (!this.activeIndex) {
        this.activeIndex = 0;
      }
      this.queue.splice(this.activeIndex, 0, { postId: post.id, preview: mode === 'lead', nextPostIds, showNextVideo });
      this.activePostSource.next({ queueItem: this.activePost, playing: true});
    } else {
      queueItem.nextPostIds = nextPostIds;
      this.playNext(post, mode === 'lead');
    }
    this.queueSource.next(this.queue);
    this.setStoredValue(this.queue);
  }

  appendToPlayQueue(post: Post | Post[], mode: string = 'premium') {
    debug('Add post to end of queue');

    this.queue = this.getStoredValue();
    if (!this.queue) {
      this.queue = [];
    }
    const originalQueueLength = this.queue.length;

    if (post instanceof Post) {
      if (!_.find(this.queue, { postId: post.id })) {
        this.queue.push({ postId: post.id, preview: mode === 'lead', showNextVideo: true });
      }
    } else {
      _.each(post, (eachPost) => {
        if (!_.find(this.queue, { postId: eachPost.id })) {
          this.queue.push({ postId: eachPost.id, preview: mode === 'lead', showNextVideo: true });
        }
      });
    }
    this.queueSource.next(this.queue);

    // If there was no active post before, set the first post of the new posts as active
    if (this.activePostSource.value && this.activePostSource.value.queueItem === undefined && this.queue.length > 0) {
      this.lastActiveIndex = originalQueueLength;
      this.activePostSource.next({ queueItem: this.queue[originalQueueLength], playing: false });
    }

    this.setStoredValue(this.queue);
  }

  removeFromPlayQueue(post?: Post | number, preview?: boolean) {
    debug('Remove post from queue');
    this.queue = this.getStoredValue();
    if (this.queue && this.queue.length > 0) {
      let index: number;
      if (post instanceof Post) {
        index = this.queue.findIndex((queueItem) => queueItem.postId === post.id && queueItem.preview === !!preview);
      } else if (typeof post === 'number') {
        index = post;
      } else {
        index = this.activeIndex;
      }

      if (index !== undefined && index !== null) {
        if (this.activeIndex === index) {
          this.playNext();
        } else {
          if (this.queue[this.lastActiveIndex + 1]) {
            this.activePostSource.next({ queueItem: this.queue[this.lastActiveIndex + 1], playing: false });
          } else {
            this.activePostSource.next(null);
          }
        }

        this.queue.splice(index, 1);
        this.queueSource.next(this.queue);
        this.setStoredValue(this.queue);
      }
    }
  }

  replaceLeadMediaWithPremium(postId) {
    const queueIndex = _.findIndex(this.queue, { postId, preview: true });
    if (!this.queue[queueIndex]) {
      throw new Error('Could not play premium media');
    } else {
      this.queue[queueIndex].preview = false;

      if (this.activePost.post) {
         // Okay to assume purchased here I think - RO
        this.activePost.post.purchased = true;
      }

      if (this.activeIndex === queueIndex) {
        this.activePostSource.next({ queueItem: this.activePost, playing: true});
      }
    }
  }

  registerVideoPlayerSnap(playerLocation: ElementRef, postId, fillWindow = false) {
    this.videoPlayerSnapSource.next({
      snapElementRef: playerLocation,
      postId,
      fillWindow
    });
  }

  private getStoredValue(): QueueItem[] {
    if (!this.sessionStorage) {
      return null;
    }
    const stringValue = this.sessionStorage.getItem('playlistqueue');
    if (!stringValue) {
      return null;
    } else {
      return _.reject(JSON.parse(this.sessionStorage.getItem('playlistqueue')), queueItem => queueItem.postId === undefined);
    }
  }

  private setStoredValue(value: QueueItem[]) {
    this.sessionStorage.setItem('playlistqueue', JSON.stringify(value));
  }

  private bookmark(queuePointer: QueueItem | number) {
    let queueIndex: number;
    if (typeof queuePointer === 'number') {
      queueIndex = queuePointer;
    } else {
      queueIndex = this.queue.findIndex((queueItem) => queueItem === queuePointer);
    }
    this.sessionStorage.setItem('playlistqueue-index', queueIndex);
  }

  private getBookmark(): number {
    const index = this.sessionStorage.getItem('playlistqueue-index');
    if (!index) {
      return 0;
    }
    return isNaN(parseInt(index, 10)) ? -1 : parseInt(index, 10);
  }
}
