import * as Debug from 'debug';
const debug = Debug('shared:NotLoggedInGuard');

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';

import { UserService } from '../services/user_service';

@Injectable()
export class NotLoggedInGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Observable<boolean>( observer => {
      this.userService.curUser$.subscribe( curUser => {
        if (curUser) {
          if (curUser.id) {
            this.router.navigate(['/browse']);
            observer.next(false);
          } else {
            observer.next(true);
          }
          observer.complete();
        }
      });
    });
  }

}
