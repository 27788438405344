import * as Debug from 'debug';
const debug = Debug('shared:ManagePostGuard');

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../types/user';
import { PostService } from '../services/post_service';
import { UserService } from '../services/user_service';

@Injectable()
export class ManagePostGuard implements CanActivate {

  constructor(
    private router: Router,
    private pService: PostService,
    private uService: UserService
  ) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debug('ManagePostGuard',routeSnapshot, state);

    const postId = routeSnapshot.params['postId'];
    // debug('postId', postId);

    return new Observable<boolean>( observer => {
      if (postId) {
        this.uService.curUser$.subscribe( u => {
          this.pService.getPost(postId)
          .then( p => {
            if (u) {
              if (p.userId === u.id) {
                // debug('success - post is owned by logged in user', p, u);
                observer.next(true);
              } else {
                // debug('fail - logged in user does not own post', p, u);
                this.router.navigate(['/']);
                observer.next(false);
              }
            }
          })
          .catch( err => {
            console.log('could not get post:', postId, err);
            this.router.navigate(['/']);
            observer.next(false);
          });
        });
      } else {
        observer.next(true);
      }
    });
  }

}
