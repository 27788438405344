import { AfterViewInit, ElementRef, Directive, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[postdScrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterViewInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private element: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.element?.nativeElement) {
        const scrollElement = this.document.body.scrollTop
          ? this.document.body
          : this.document.documentElement;
        const el: HTMLDivElement = this.element.nativeElement;
        const rect = el.getBoundingClientRect();
        scrollElement.scrollTo({ top: scrollElement.scrollTop + (rect.top - 120) });
      }
    }
  }
}
