import { NgModule } from '@angular/core';
import { PostdCommonModule } from '../../../../common/common.module';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../../../../common/shared-pipes.module';
import { StarRatingModule } from '../../../../frontend/core/forms/star-rating/star-rating.module';

import { ThumbnailComponent } from '../../../../frontend/core/posts/thumbnail/thumbnail.component';

@NgModule({
  declarations: [
    ThumbnailComponent
  ],
  imports: [ PostdCommonModule, RouterModule, SharedPipesModule, StarRatingModule ],
  exports: [ ThumbnailComponent ]
})

export class ThumbnailModule {}
