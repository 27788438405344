
import {throwError as observableThrowError,  Observable } from 'rxjs';
import * as Debug from 'debug';
const debug = Debug('shared:PlaylistService');

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';

import { LocationService } from './location_service';
import { PurchaseService, PurchaseMicroService } from './purchase_service';
import { Playlist } from '../types/playlist';

import * as _ from 'lodash';

@Injectable()
export class PlaylistService {
  constructor(
    private http: HttpClient,
    private locationService: LocationService,
    private purchaseService: PurchaseService
  ) {
    debug('PlaylistService constructor');
  }

  getPlaylist(playlistId: string) {
    return this.http.get<Playlist>('/api/playlist/' + playlistId + '/info' , { });
  }

  editPlaylistName(playlist: Playlist, newName?: string) {
    return this.http.put<Playlist>('/api/playlist/' + playlist.id + '/name' , { name: newName || playlist.name });
  }

  editPlaylistInfo(playlist: Playlist, playlistInfo: { description?: string; postIds?: string[] }) {
    return this.http.patch<Playlist>('/api/playlist/' + playlist.id + '/update' , playlistInfo);
  }

  addPostToPlaylist(playlistId: string, postId: string) {
    return this.http.put<Playlist>('/api/playlist/' + playlistId + '/post/' + postId, {});
  }

  removePostsFromPlaylist(playlist: Playlist, postIds: string | string[]) {
    if (typeof postIds === 'string') {
      _.pull(playlist.postIds, postIds);
    } else {
      _.pull(playlist.postIds, ...postIds);
    }
    const payload = {
      postIds: playlist.postIds
    };
    return this.http.patch<Playlist>('/api/playlist/' + playlist.id + '/update', payload);
  }

  deletePlaylist(playlistId: string) {
    return this.http.delete<void>('/api/playlist/' + playlistId, { });
  }

  doesPlaylistHavePost(playlist: Playlist, postId: string): boolean {
    return playlist.postIds.indexOf(postId) !== -1;
  }

  preparePurchasePlaylist(playlist: Playlist, postIds: string[]): Observable<{ price: number; purchaseService: PurchaseMicroService<Playlist> }> {
    const self = this;
    if (postIds.length > 0) {
      const playlist_url = self.locationService.getCurrExternalUrl();
      const api_url = '/api/playlist/' + playlist.id + '/purchase/token/create';

      const payload: { posts: string; url?: string } = {
        posts: postIds.join(',')
      };

      // note this url is only needed for localhost dev so that the backend sends you
      // signed token that includes the localhost url in the signed token
      if (playlist_url) {
        payload.url = playlist_url;
      }
      debug('preparePurchasePost url', api_url);
      return this.http.post<{ token: string; price: number }>(api_url, payload).pipe(mergeMap(response =>
        this.purchaseService.preparePurchase<Playlist>(response.token, {
          flatMap: token => this.http.post<Playlist>('/api/playlist/' + playlist.id + '/purchase/token/validate', { token })
        }).pipe(map(purchaseService => ({
            price: response.price,
            purchaseService
          })))
      ));
    } else {
      return observableThrowError(new Error('NO_POSTS'));
    }
  }
}
