import * as Debug from 'debug';
const debug = Debug('shared:ManageChannelGuard');

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../types/user';
import { ChannelService } from '../services/channel_service';
import { UserService } from '../services/user_service';

@Injectable()
export class ManageChannelGuard implements CanActivate {

    constructor(
        private router: Router,
        private cService: ChannelService,
        private uService: UserService
      ) {
      }

      canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const chanId = routeSnapshot.params['chanId'];

        return new Observable<boolean>( observer => {
          if (chanId) {
            this.uService.curUser$.subscribe( u => {
              this.cService.getChannelInfo(chanId)
              .subscribe( c => {
                if (u) {
                  if (c.userId === u.id) {
                    // debug('success - channel is owned by logged in user', c, u);
                    observer.next(true);
                  } else {
                    // debug('fail - logged in user does not own channel', c, u);
                    this.router.navigate(['/']);
                    observer.next(false);
                  }
                }
              });
            });
          } else {
            observer.next(true);
          }
        });
      }

}
